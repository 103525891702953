import Profile from '@/pages/profile/Profile.vue'
import store from '@/store/store'

const routes = [
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: (to, from, next) => {    
      if (store.getters.isVerified) {
        next()
      } else if (!store.getters.isAuthenticated) {
        next({name: 'Signin', params: {fromProjects: false} })
      } else {
        next({name: 'RequestPasswordReset'})
      }
    }
  }
]

export default routes
