export default {
  name: 'TilesMap',
  components: {
  },
  props: {
    page: {
      type: Array,
      required: true
    },
    zoomLevel: {
      type: Number,
      require: true
    },
  },
  data: () => ({
    dialog: false,
    pageCentroid: [0, 0],
  }),
  computed: {
    pageGeometries () {
      let geometries = this.page.flat().map(task => {
        return this.geomFromTileCoords(parseInt(task.taskX), parseInt(task.taskY), parseInt(this.zoomLevel))
      })
      this.updatePageCentroid(geometries)
      return geometries
    },
    initialZoom () {
      let zoom = Math.max(this.zoomLevel - 4, 3)
      return zoom
    },
    colors: function () {
      return this.$vuetify.theme.themes.light
    },
  },
  methods: {
    // https://gitlab.gistools.geog.uni-heidelberg.de/giscience/disaster-tools/heigit-crowdmap/python-crowdmap-workers/-/blob/master/mapswipe_workers/mapswipe_workers/utils/tile_functions.py
    pixelCoordsZoomToLatLon(pixelX, pixelY, zoom) {
      let mapSize = 256 * Math.pow(2, zoom)
      let x = (pixelX / mapSize) - 0.5
      let y = 0.5 - (pixelY / mapSize)
      let lon = 360 * x
      let lat = 90 - 360 * Math.atan(Math.exp(-y * 2 * Math.PI)) / Math.PI
      return [lon, lat]
    },
    geomFromTileCoords(tileX, tileY, zoom) {
      // this only works with 256x256 tiles, right?
      let leftPixelX = tileX * 256
      let topPixelY = tileY * 256
      let [lonLeft, latTop] = this.pixelCoordsZoomToLatLon(leftPixelX, topPixelY, zoom)

      let rightPixelX = (tileX + 1) * 256
      let bottomPixelY = (tileY + 1) * 256
      let [lonRight, latBottom] = this.pixelCoordsZoomToLatLon(rightPixelX, bottomPixelY, zoom)
      
      let coordinates = [[
        [lonLeft, latTop],
        [lonRight, latTop],
        [lonRight, latBottom],
        [lonLeft, latBottom],
        [lonLeft, latTop]
      ]]
      return coordinates
    },
    updatePageCentroid(geometries) {
      const nodes = geometries.flat(2)
      const x = nodes.map(node => node[0])
      const y = nodes.map(node => node[1])
      const cx = (Math.min(...x) + Math.max(...x)) / 2;
      const cy = (Math.min(...y) + Math.max(...y)) / 2;
      const centroid = [cx, cy]
      this.pageCentroid = centroid
    },
    fitView(duration = 600, delay = 0) {
      setTimeout(() => {
        this.$refs.mapView?.animate({
          zoom: this.initialZoom,
          center: this.pageCentroid,
          duration: duration,
        })
      }, delay)
    },
    close () {
      this.$emit('close')
    },
  },
}