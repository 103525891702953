export default {
  name: 'ChangeInstructions',
  props: {
    answers: {
      type: Array,
      required: true
    },
    lookFor: {
      type: String,
      required: true
    },
    attribution: {
      type: String,
      require: false
    },
    textColor: {
      type: Function,
      require: true
    },
    manualUrl: {
      type: String,
      require: false
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.dialog = this.first
  },
}