
export default {
  questionInstructions: {
    howToContribute: 'How to contribute?',
    provideYourAnswer: 'Provide your answer',
    lookAtThe: 'Look at the',
    image: 'image | images',
    video: 'video | videos',
    answerButton: 'and provide your answer to the question by clicking or tapping the appropriate answer button',
    afterAnswering: 'After answering, a second question will appear',
    indicateConfidence: 'Indicate how sure you are that your answer to the first question is correct',
    useButtonsToNavigate: 'Use buttons to navigate',
    moveBack: 'Move back to change your answers on previous questions',
    moveForward: 'Move forward after having provided an answer to the current question',
    saveYourAnswers: 'Save your answers',
    seenAll: 'as soon as you have seen all',
    answeredAll: 'and answered all the questions',
    imageCredits: 'Image credits',
    zoomTitle: 'Take a closer look',
    zoomInstruction: '@:global.zoomIn and @.lower:global.zoomOut of the image',
    resetInstruction: '@:global.resetZoom and recenter the view',
    }
  }
