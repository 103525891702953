import appConfig from '@/config/app-config'

let options = {
    baseURL: appConfig.firebaseConfig.databaseURL, // String, an empty string is the default
    isAuthenticated: () => {
        // run your logic to determine if a user is authenticated and return a boolean
    },
    getVueInstance: () => {
        return null
    },
    getBearerToken: () => {
        // run your logic and return the Bearer token
    },
    appendLocaleToHeader: false,
    appendLocaleToGetUrl: false,
}

export default options