import Tile from '@/components/tile/Tile.vue'

export default {
  name: 'TilesInstructions',
  components: {
    Tile,
  },
  props: {
    instructionMsg: {
      type: String,
      required: true
    },
    answers: {
      type: Array,
      required: true
    },
    attribution: {
      type: String,
      require: false
    },
    exampleTileUrls: {
      type: Array,
      require: true
    },
    manualUrl: {
      type: String,
      require: false
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dialog: false,
    eyeOff: false,
    allTilesSelected: false,
    tileSelected: false,
  }),
  computed: {
    title () {
      return "title"
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.dialog = this.first
  },
}