export default {
  name: 'ValidateInstructions',
  props: {
    question: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    textColor: {
      type: Function,
      require: true
    },
    manualUrl: {
      type: String,
      require: false
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dialog: false,
    eyeOff: false,
  }),
  methods: {
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.dialog = this.first
  },
}
