import appConfig from '@/config/app-config'
import { browserLocalPersistence, getAuth, setPersistence, signOut, updateProfile } from "firebase/auth";
import { getDatabase, ref, push, set, update, onValue } from "firebase/database";
import store from "@/store/store";

export default {
  name: "ProfilePage",
  components: {},
  data: () => ({
    changeUserNameDialog: false,
    deleteDialog: false,
    isFormValid: false,
    joinDialog: false,
    leaveDialog: false,
    leaveUserGroupId: null,
    newUsername: '',
    user: null,
    userGroups: [],
    userGroupsOfUser: [],
    selectedUserGroup: null,
    signOutDialog: false,
  }),
  computed: {
    username() {
      return this.user?.displayName || this.$t(`profile.unnamedUser`);
    },
    communityDashboardUrl() {
      try {
        return new URL(appConfig.communityDashboardUrl)
      } catch (err) {
        return false
      }
    },
    userStatsUrl() {
      try {
        return new URL(`/user/${this.user.uid}`, this.communityDashboardUrl)
      } catch (err) {
        return false
      }
    },
  },
  methods: {
    bindUserGroups() {
      const db = getDatabase()
      onValue(ref(db, "v2/userGroups"), (snapshot) => {
        const data = snapshot.val() || {}
        const destructure = ([groupId, group]) => ({groupId, ...group, })
        this.userGroups = Object.entries(data).map(destructure)
      })
    },
    bindUserGroupsOfUser() {
      const userId = this.user.uid
      const db = getDatabase()
      onValue(ref(db, `v2/users/${userId}/userGroups/`), (snapshot) => {
        const data = snapshot.val() || {}
        const groupKeys = Object.keys(data)
        this.userGroupsOfUser = this.userGroups.filter(userGroup => groupKeys.includes(userGroup.groupId))
      })
    },
    changeUserName() {
      const context = this
      const userId = this.user.uid
      const db = getDatabase()

      if((this.newUsername?.length ?? 0) >= 4) {
        updateProfile(this.user, { displayName: this.newUsername })
          .then(() => {
            set(ref(db, `/v2/users/${userId}/username`), this.newUsername)
          })
          .then(() => {
            this.changeUserNameDialog = false
            context.showSuccess(this.$t(`profile.usernameChanged`))
          })
          .catch(() => context.showError(this.$t(`profile.usernameChangeFailed`)))
      }
    },
    deleteAccount() {
      const context = this;
      this.user
        .delete()
        .then(() => {
          this.deleteDialog = false;
          this.user = null;
          store.commit("isAuthenticated", false);
          this.$router.push({ name: "Signin" });
          context.showSuccess(context.$t("profile.accountDeleted"));
        })
        .catch(() => {
          context.showError(this.$t(`profile.accountDeletionFailed`));
        });
    },
    getGroupStatsUrl(userGroupId) {
      try {
        return new URL(`/user-group/${userGroupId}`, this.communityDashboardUrl)
      } catch (err) {
        return false
      }
    },
    joinUserGroup() {
      this.joinDialog = false
      const context = this;
      const userId = this.user.uid
      const userGroupId = this.selectedUserGroup.groupId
      const db = getDatabase()
      const updates = {}

      push(ref(db, '/v2/userGroupMembershipLogs/')) // generates unique log id
        .then((logId) => {
          if(logId) {
            updates[`/v2/users/${userId}/userGroups/${userGroupId}`] = true;
            updates[`/v2/userGroups/${userGroupId}/users/${userId}`] = true;
            updates[`/v2/userGroupMembershipLogs/${logId.key}`] = {
              userId,
              userGroupId,
              action: 'join',
              timestamp: new Date().getTime(),
            };

            update(ref(db), updates)
              .then(() => context.showSuccess(this.$t(`profile.joinedGroup`)))
              .catch(() => context.showError(this.$t(`profile.failedToJoinGroup`)))

            this.selectedUserGroup = null
          } else {
            context.showError(this.$t(`profile.failedToJoinGroup`))
            console.error('Cannot get new key to push membership log')
          }
        })
        .catch(() => context.showError(this.$t(`profile.failedToJoinGroup`)))
    },
    handleLeave(userGroupId) {
      this.leaveUserGroupId = userGroupId
      this.leaveDialog = true
    },
    leaveUserGroup() {
      this.leaveDialog = false
      const context = this;
      const userId = this.user.uid
      const userGroupId = this.leaveUserGroupId
      const db = getDatabase()
      const updates = {}

      push(ref(db, '/v2/userGroupMembershipLogs/')) // generates unique log id
        .then((logId) => {
          if(logId) {
            updates[`/v2/users/${userId}/userGroups/${userGroupId}`] = null;
            updates[`/v2/userGroups/${userGroupId}/users/${userId}`] = null;
            updates[`/v2/userGroupMembershipLogs/${logId.key}`] = {
              userId,
              userGroupId,
              action: 'leave',
              timestamp: new Date().getTime(),
            };

            update(ref(db), updates)
              .then(() => context.showSuccess(this.$t(`profile.leftGroup`)))
              .catch(() => context.showError(this.$t(`profile.failedToLeaveGroup`)))

          } else {
            context.showError(this.$t(`profile.failedToLeaveGroup`))
            console.error('Cannot get new key to push membership log')
          }
        })
        .catch(() => context.showError(this.$t(`profile.failedToLeaveGroup`)))
    },
    signOut() {
      const context = this
      const auth = getAuth()
      setPersistence(auth, browserLocalPersistence)
        .then(() => signOut(auth))
        .then(() => {
            store.commit('isAuthenticated', false)
            store.commit('isVerified', false)
            location.reload()
        })
        .catch(() =>  context.showError(this.$t(`profile.failedToSignOut`)))
    }
  },
  mounted() {
    const auth = getAuth();
    this.user = auth.currentUser;
    this.bindUserGroups();
    this.bindUserGroupsOfUser();
  },
};
