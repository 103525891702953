export default {
  name: 'Tile',
  props: {
    url: {
      type: String,
      require: true
    },
    urlB: {
      type: String,
      require: false
    },
    spinner: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: Number,
      default: 1
    },
    opacityB: {
      type: Number,
      default: 1
    },
  },
}