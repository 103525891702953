import TaskProgress from '@/components/task-progress/TaskProgress.vue'
import Tile from '@/components/tile/Tile.vue'
import TilesMap from '@/components/tiles-map/TilesMap.vue'
import ChangeInstructions from '@/components/change-instructions/ChangeInstructions.vue'
import tinyColor2 from 'tinycolor2'

export default {
  name: 'Change',
  components: {
    TaskProgress,
    Tile,
    TilesMap,
    ChangeInstructions,
  },
  props: {
    tasks: {
      type: Array,
      require: true
    },
    project: {
      type: Object,
      require: true
    },
    group: {
      type: Object,
      require: true
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selected: undefined,
      results: {},
      task: {},
      taskIndex: 0, 
      isAnswered: {}, 
      allAnswered: false,
    }
  },
  computed: {
    isLastTask () {
      return (this.taskIndex + 1) === this.tasks.length
    },
    isFirstTask () {
      return this.taskIndex === 0
    },
    // ------
    answers() {
      const answers = this.project.answerLabels

      // if answer.value is undefined, use answer index (integer)
      const setAnswerValue = (answer, index) => {
        answer.value ??= index
        return answer
      }

      answers.map(setAnswerValue)
      return answers
    },
    tileMaxSize() {
      if(this.$vuetify.breakpoint.smAndDown) {
        return "35vh"
      }
    },
    attribution () {
      const attribution = [this.project.tileServer.credits, this.project.tileServerB.credits]
      return attribution.join('; ')
    },
  },
  methods: {
    contrastingTextColor (bg) {
      const textColor = tinyColor2(bg).isLight() ? 'black' : 'white'
      return textColor
    },
    answerShortkey(answerIndex) {
      const shortkey = answerIndex < 9 ? answerIndex + 1 : ''
      return shortkey
    },
    /**
     * Update result with value
     * @param {*} value 
     * @param {*} slot 
     */
    answerSelected (value) {
      this.selected = value
      this.results[this.task.taskId] = value 
      this.isAnswered[this.task.taskId] = true
      this.allAnswered = this.allAnswered || this.isLastTask && this.isAnswered[this.task.taskId]
    },
    /**
     * Go to task by task index, 
     * create result object if not existent and
     * reset question index
     * @param {*} index 
     */
    goToTask (index) {
      this.task = this.tasks[index]
      this.results[this.task.taskId] ??= null
      this.isAnswered[this.task.taskId] ??= false
      this.selected = this.results[this.task.taskId]
    },
    /**
     * Go to next task
     */
    nextTask () {
      this.taskIndex++
      this.goToTask(this.taskIndex)
    },
    /**
     * Go back to previous task
     */
    previousTask () {
      this.taskIndex--
      this.goToTask(this.taskIndex)
    },
    /**
     * Check if an answer button value equals the
     * currently selected answer and return string
     * to toggle between checked/unchecked radiobox icon 
     * @param {*} answerValue 
     * @returns 
     */
    toggleRadiobox (value) {
      const taskId = this.task.taskId
      const activeAnswer = this.results[taskId]
      return value === activeAnswer ? "marked" : "blank"
    },
    /** 
     * Back button event handler
     */
    back () {
      if(!this.isFirstTask) this.previousTask()
    },
    /**
     * Forward button event handler
     */
    forward () {
      if(this.isLastTask || !this.isAnswered[this.task.taskId]) {
        return
      } else {
        this.nextTask()
      } 
    },
    /**
     * Emit answerSheets for all tasks as events and save the task answers
     * @emits answered
     * @emits save
     */
    answerAndSave() {
      let entries = Object.entries(this.results)
      entries.forEach(entry => {
        let answerSheet = entry[1]
        let taskId = entry[0]
        this.$emit('answered', { taskId: taskId, answerSheet: answerSheet })
      })
      this.$emit('save')
    },
  },
  created() {
    this.goToTask(this.taskIndex)
  }
}