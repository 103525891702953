import About from '@/pages/about/About.vue'


const routes = [
  {
    path: '/about',
    name: 'About',
    component: About,
    beforeEnter: (to, from, next) => {
      next()
    }
  }
]

export default routes
