
export default {
  profile: {
    accountDeleted: 'Account deleted! Sorry to see you go...',
    accountDeletionFailed: 'Could not delete your account. Please login again to confirm you want to delete your account.',
    changeUsername: 'Change Username',
    currentUsername: 'Current Username',
    deleteAccount:'Delete Account',
    deleteYourAccount: 'Do you really want to delete you account? This action cannot be undone!',
    exploreUserGroups: 'Explore user groups',
    failedToJoinGroup: 'Failed to join user group',
    failedToLeaveGroup: 'Failed to leave user group',
    failedToSignOut: 'Failed to sign out',
    groupStats: 'Group stats',
    join: 'Join',
    joinedGroup: 'Joined user group',
    joinNewGroup: 'Join new group',
    joinThisGroup: 'Do you want to join this group?',
    leave: 'Leave',
    leaveThisGroup: 'Do you really want to leave this group? Contributions you made while being a member of the group will still be counted towards the group.',
    leftGroup: 'Left user group',
    memberships: 'Memberships',
    newUsername: 'New Username',
    noGroupsAvailable: 'No groups available',
    profile: 'Profile',
    signOut: 'Do you really want to sign out?',
    unnamedUser: 'Unnamed',
    userGroups: 'User Groups',
    usernameChanged: 'Username changed',
    usernameChangeFailed: 'Failed to change username',
    userStats: 'User stats',
  }
}
