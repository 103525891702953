
export default {
  tiles: {
    compare: 'Compare the',
    layer: ' layer',
    tiles: 'image tiles',
    withTiles: 'with the @:tiles.tiles',
    lookFor: 'Look for',
    inTiles: 'in the @:tiles.tiles',
    overlay: 'overlay',
    moveLeft: 'Move left by one column | Move left by {columns} columns',
    moveRight: 'Move right by one column | Move right by {columns} columns',
  }
}
