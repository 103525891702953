import {ModelService} from 'vue-rest-client'
import httpClientOptions from '@/common/http-client-options'
import utils from '@/support/utils'

const options = {
  httpClientOptions: httpClientOptions,
  transformRequest: (request) => {
    utils.addIdTokenToRequest(request)  
  },
  raw: true
}
const projectService = new ModelService('v2/projects', 'projects', options)

export default projectService

