export default {
  global: {
    languageLabel: 'English',
    send: 'Send',
    save: 'Save',
    login: 'Login',
    close: 'Close',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    resetView: 'Reset view',
    resetZoom: 'Reset zoom',
    select: 'Select',
    next: 'Next',
    cancel: 'Cancel',
    leave: 'Leave',
    sure: 'Are you sure?',
    opacity: 'opacity',
    ok: 'OK',
    task: 'Task | Tasks',
    decrease: 'decrease',
    increase: 'increase',
    toggle: 'toggle',
    toggleOpacity: '@.capitalize:global.toggle @:global.opacity',
    moreInfo: "More info",
    unknown: "unknown",
    selected: "selected",
    selectAll: "Select all",
    clearSelection: "Clear selection",
  },
  errors: {
    unknown: 'Unknown error',
    http: {
      403: 'Something went wrong, please contact the site administrators. Action forbidden'
    }
  },
  user: {
    hi: 'Hi',
    signout: 'Signout',
    loginFailed: 'Login failed. Please check if the user and the password are valid'
  }
}

