export default {
  global: {
    languageLabel: 'Deutsch',
    send: 'Senden',
    save: 'Speichern',
    login: 'Anmelden',
    close: 'Schließen',
    zoomIn: 'Einzoomen',
    zoomOut: 'Auszoomen',
    resetView: 'Ansicht zurücksetzen',
    resetZoom: 'Zoom zurücksetzen',
    select: 'Auswählen',
    next: 'Nächste',
    cancel: 'Abbrechen',
    leave: 'Verlassen',
    sure: 'Bist du sicher?',
    ok: 'OK',
    opacity: 'Durchsichtigkeit',
    task: 'Aufgabe | Aufgaben',
    decrease: 'Verringern',
    increase: 'Erhöhen',
    toggle: 'Umschalten',
    toggleOpacity: '@:global.opacity @.lower:global.toggle',
    moreInfo: "Mehr Info",
    unknown: "unbekannt",
    selected: "ausgewählt",
    selectAll: "Alles auswählen",
    clearSelection: "Auswahl aufheben",
  },
  errors: {
    unknown: 'Unbekannter Fehler',
    http: {
      403: 'Etwas ist schiefgelaufen. Bitte kontaktiere die Administration. Aktion nicht erlaubt'
    }
  },
  user: {
    hi: 'Hi',
    signout: 'Abmelden',
    loginFailed: 'Anmeldung gescheitert. Bitte prüfe, ob Nutzername und Passwort gültig sind'
  }
}
