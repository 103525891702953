import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUSTranslations from './translations/en-us/builder'
import deDETranslations from './translations/de-de/builder'
import appConfig from '@/config/app-config'

Vue.use(VueI18n)

const build = () => {
  const i18n = {
    locale: appConfig.defaultLocale,
    messages: { 'en-us': {}, 'de-de': {} },
    fallbackLocale: appConfig.defaultLocale
  }

  i18n.messages['en-us'] = enUSTranslations.build()
  i18n.messages['de-de'] = deDETranslations.build()    

  /* 
    Uncomment the line below to output, via browser 
    console, a merged version of the translation source 
    file when the application runs. If necessary, you can 
    replace 'en-us' below by any of the supported 
    locale, like 'de-de' or 'fr-fr'.
  */
  // console.log(JSON.stringify(i18n.messages['en-us']))

  Vue.use(VueI18n)
  return new VueI18n(i18n)
}

const getShortLocale = (locale) => {
  let short = locale.split('-')[0]
  return short
}

export default {
  build,
  getShortLocale
}
