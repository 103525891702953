import Toaster from '@/components/toaster/Toaster.vue'
import Info from '@/components/dialogs/info/Info.vue'
import Header from '@/components/header/Header.vue'
import Sidebar from '@/components/sidebar/Sidebar.vue'
import Footer from '@/components/footer/Footer.vue'
import appConfig from '@/config/app-config.js'

// Import self-hosted google fonts
import '@/assets/css/font-roboto-latin:300,400,500,700.css'
import '@/assets/css/font-open-sans-latin:400.css'


export default {
  name: 'App',
  components: {
    appHeader: Header,
    appToaster: Toaster,
    appInfo: Info,
    appSidebar: Sidebar,
    appFooter: Footer,
  },
  data () {
    return {
      showLoading: false,
      title: appConfig.footerAppName,
    }
  },
  computed: {
    showProgress () {
      return !this.showLoading
    },
    mainHeight () {
      return `calc(100% - ${this.$store.getters.footerHeight + this.$store.getters.headerHeight}px)`
    },
  },
  watch: {
    '$store.getters.isAuthenticated': function (newVal) {
      if (!newVal) {
        this.$router.push({name: 'Signin'})
      }
    },
  },
  created() {
    // Register the listener for the showLoading
    let context = this
    this.eventBus.$on('showLoading', (value) => {
      context.showLoading = value
    })
    this.eventBus.$on('titleChanged', (title) => {
      this.title = title
    })
  }
}