const constants = {  
  menuItems: [
    {
      href: '/#/',
      title: 'Home',
      icon: 'mdi-home',
      showIcon: false
    },
    {
      href: '/#/projects',
      title: 'Projects',
      icon: 'mdi-link',
      showIcon: false
    },
    /*
    {
      href: '/#/about',
      title: 'About',
      icon: 'mdi-link',
      showIcon: false
    },
    {
      href: '/#/help',
      title: 'Help',
      icon: 'mdi-help-circle',
      showIcon: false
    },
    
    {
      href: '/#/auth',
      title: 'SignInSignUp',
      icon: 'mdi-account-circle',
      showIcon: true,
      requiresNotToBeAuthenticated: true
    }
    */
  ],
  links: {
    source: '#',
    reportIssue: '#',
    imprint: 'https://heigit.org/imprint/',
    privacyNotice: '/#/privacy'
  }
}

/* 
translations for the menu item titles are provided with the respective 
languages files in src/components/h-menu/i18n/
*/

export default constants
