
export default {
  digitisationInstructions: {
    howToContribute: 'Wie kannst du beitragen?',
    modeTitle: 'Bearbeitungsmodus ändern',
    drawFeature: 'Neue Objekte einzeichnen',
    modifyFeature: 'Objekte verändern',
    deleteFeature: 'Objekte löschen',
    useButtonsToNavigate: 'Navigationstasten',
    moveBack: 'Gehe zurück zum vorigen Gebiet',
    moveForward: 'Gehe weiter zum nächsten Gebiet',
    toggleOpacityTitle: '@:global.opacity @.lower:global.toggle',
    toggleOpacityInstruction: 'Schalte die Durchsichigkeit der oberen Schicht für eine bessere Sicht auf die Bildkacheln um',
    resetTitle: 'Ansicht zurücksetzen',
    resetInstruction: 'Zoom auf das aktuelle Gebiet',
    saveYourContributions: 'Speichere deine Beiträge',
    seenAll: 'der Beiträge, sobald du alle Gebiete kartiert hast',
    imageCredits: 'Bildnachweis',
   },
}
