import Auth from "@/services/auth"

export default {
  name: 'Signup',
  data() {
    return {
      isFormValid: false,
      displayName: '',
      password: '',
      confirmPassword: '',
      email: '',
      showPassword: false,
      consent: false,
      rules: {
        min4: v => v.length >= 4 || this.$t('auth.minChar', {number: "4"}),
        min6: v => v.length >= 6 || this.$t('auth.minChar', {number: "6"}),
        passwordMatch: () => this.password === this.confirmPassword || this.$t('auth.passwordMatch'),
        required: value => !!value || this.$t('auth.required'),
        email: v=> {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || this.$t('auth.invalidEmail')
        },
      },
    }
  },
  watch: {
    // Trigger translation of validation error message on text fields
    '$i18n.locale' () {
      if(this.$refs.email.hasError) this.$refs.email.validate()
      if(this.$refs.password.hasError) this.$refs.password.validate()
      if(this.$refs.confirmPassword.hasError) this.$refs.confirmPassword.validate()
    }
  },
  methods: {
    signup () {
      if (this.isFormValid) {
        let context = this
        Auth.signup(this.email, this.password, this.displayName)
          .then(() => {       
            context.$emit('routerPush', 'Home')
            context.showSuccess(context.$t('auth.followInstructionOnYourEmail'))
          })
          .catch(error => {
            let errorMsg = this.$t(`auth.authErrors.${error.code}`)
            if (!errorMsg) {
              errorMsg = this.$t(`auth.signupFailed`)
            }
            context.showError(errorMsg)
          })
      }     
    }
  }
}