import Auth from '@/pages/auth/Auth.vue'
import store from '@/store/store'


const routes = [
  {
    path: '/auth',
    name: 'Authentication',
    component: Auth,
    beforeEnter: (to, from, next) => {
      next({name: 'Signin',})
    }
  },
  {
    path: '/auth/signin',
    name: 'Signin',
    component: Auth,
    beforeEnter: (to, from, next) => {
      if (store.getters.isVerified) {
        next({name: 'Projects'})
      } else {
        next()
      }
    }
  },
  {
    path: '/auth/signup',
    name: 'Signup',
    component: Auth,
    beforeEnter: (to, from, next) => {
      if (store.getters.isVerified) {
        next({name: 'Projects'})
      } else {
        next()
      }
    }
  },
  {
    path: '/auth/request-password-reset',
    name: 'RequestPasswordReset',
    component: Auth,
    beforeEnter: (to, from, next) => {
      if (store.getters.isVerified) {
        next({name: 'Projects'})
      } else {
        next()
      }
    }
  }
]

export default routes
