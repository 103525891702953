/* eslint-disable no-undef */
import Vue from 'vue'
import Vuex from 'vuex'
import appUI from './modules/app-ui'
import appState from './modules/app-state'
import loader from '@/support/loader'

Vue.use(Vuex)

const storeBuilder = {
  state: {
  },
  getters: {
  },
  mutations: {

  },
  modules: {
    appUI,
    appState
  }
}

// load and get all routes from *.route.js default export using custom loader
const componentsModules = loader.load(require.context('@/components/', true, /\.store\.js$/), true)
var mergedModules = {...componentsModules}

// load and get all routes from *.route.js default export using custom loader
const pageModules = loader.load(require.context('@/pages/', true, /\.store\.js$/), true)
mergedModules = {...pageModules}

for (var key in mergedModules) {
  storeBuilder.modules[key] = mergedModules[key]
}

const store = new Vuex.Store(storeBuilder)

export default store
