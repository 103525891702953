import TaskProgress from '@/components/task-progress/TaskProgress.vue'
import Tile from '@/components/tile/Tile.vue'
import TilesInstructions from '@/components/tiles-instructions/TilesInstructions.vue'
import TilesMap from '@/components/tiles-map/TilesMap.vue'

export default {
  name: 'Tiles',
  components: {
    TaskProgress,
    Tile,
    TilesInstructions,
    TilesMap,
  },
  props: {
    tasks: {
      type: Array,
      require: true
    },
    project: {
      type: Object,
      require: true
    },
    group: {
      type: Object,
      require: true
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      trigger: 0,
      results: {},
      columnIndex: 0,
      progressIndex: 0,
      sortedTasks: [],
      selectedTaskIds: [],
      endReached: false,
      aspectRatio: 1,
      transparent: false,
      magnifyDialog: false,
      magnifyTask: null,
    }
  },
  computed: {
    answers() {
      const answers = this.project.answerLabels

      // if answer.value is undefined, use answer index (integer)
      const setAnswerValue = (answer, index) => {
        answer.value ??= index
        return answer
      }

      answers.map(setAnswerValue)
      return answers
    },
    attribution () {
      const attribution = [this.project.tileServer.credits]
      const tileServerB = this.project.tileServerB
      if(tileServerB) attribution.push(tileServerB.credits)
      return attribution.join('; ')
    },
    instructionMsg () {
      const msg = this.project.tileServerB ?
        this.$t('tiles.compare') + ' ' + `${this.project.lookFor}` + this.$t('tiles.layer') + ' ' + this.$t('tiles.withTiles') :
        this.$t('tiles.lookFor') + ' ' +`${this.project.lookFor}` + ' ' + this.$t('tiles.inTiles')
      return msg
    },
    rowsPerPage() {
      // all pages should have 3 rows of image tiles
      return 3
    },
    columnsPerPage() {
      // set columns of tiles per page based on window aspect ratio
      let possibleColumnsPerPage = [1, 2, 3, 4, 6, 12]
      let columnsRatio = this.rowsPerPage * this.aspectRatio * 1.33
      const columnsPerPage = possibleColumnsPerPage.reduce((a, b) => {
        return Math.abs(b - columnsRatio) < Math.abs(a - columnsRatio) ? b : a;
      });
      return columnsPerPage
    },
    tasksPerPage() {
      let tasksPerPage = this.rowsPerPage * this.columnsPerPage
      return tasksPerPage
    },
    totalColumns() {
      let totalColumns = Math.ceil(this.tasks.length / this.rowsPerPage)
      return totalColumns
    },
    page() {
      this.trigger
      this.columnIndex = Math.min(this.columnIndex, this.totalColumns - this.columnsPerPage)
      let begin = Math.max(this.columnIndex * this.rowsPerPage, 0)
      let end = begin + this.tasksPerPage
      let pageTasks = this.sortedTasks
        .slice(begin, end)
        .map(this.appendColorAndLabel)
      this.updateSelection(pageTasks.map(t => t.taskId))
      let uniqueY = [...new Set( pageTasks.map(task => parseInt(task.taskY))) ].sort()
      let page = []
      for (let y of uniqueY) {
        let row = pageTasks
          .filter(task => parseInt(task.taskY) === y)
          .sort((a, b) => parseInt(a.taskX) > parseInt(b.taskX))
        page.push(row)
      }
      this.progressIndex = (this.columnIndex + this.columnsPerPage) * this.rowsPerPage
      if(pageTasks.length <= this.tasksPerPage) {
        this.endReached = true
        this.progressIndex = this.tasks.length
      }
      return page
    },
    isLastPage() {
      let rightColumnIndex = Math.max(this.columnIndex, 0) + this.columnsPerPage
      let lastPage = rightColumnIndex >= this.totalColumns
      return lastPage
    },
    tilesInSelection() {
      return this.selectedTaskIds.length > 0
    },
    allTilesSelected() {
      return this.selectedTaskIds.length === this.page.flat().length
    },
    bckDisabled() {
      return this.columnIndex <= 0
    },
    fwdDisabled() {
      return this.isLastPage
    },
  },
  methods: {
    appendColorAndLabel(task) {
      let value = this.results[task.taskId]
      let selectedAnswer = this.answers[value]
      task.value = selectedAnswer.value
      task.color = selectedAnswer.color
      task.label = selectedAnswer.label
      task.answerIndex = value
      return task
    },
    back() {
      if(!this.bckDisabled) this.columnIndex--
    },
    fastBck() {
      if(!this.bckDisabled) {
        let target = this.columnIndex - this.columnsPerPage
        this.columnIndex = Math.max(target, 0)
      }
    },
    forward() {
      if(!this.fwdDisabled) {
        this.columnIndex++
        if (this.isLastPage) this.endReached = true
      }
    },
    fastFwd() {
      if(!this.fwdDisabled) {
        let target = this.columnIndex + this.columnsPerPage
        let max = this.totalColumns - this.columnsPerPage
        this.columnIndex = Math.min(target, max)
        if (this.isLastPage) this.endReached = true
      }
    },
    fastBckColumns() {
      return Math.min(this.columnIndex, this.columnsPerPage)
    },
    fastFwdColumns() {
      const remaining = this.totalColumns - this.columnIndex - this.columnsPerPage
      return Math.min(remaining, this.columnsPerPage)
    },
    tileClicked(taskId) {
      let currentAnswer = this.results[taskId]
      if (!this.tilesInSelection) {
        this.bumpAnswer(taskId, currentAnswer)
      } else if (this.selectedTaskIds.includes(taskId)) {
        this.selectedTaskIds.map(t => this.bumpAnswer(t, currentAnswer))
      }
    },
    bumpAnswer(taskId, currentAnswer) {
      if (currentAnswer < this.answers.length - 1) {
        this.results[taskId] = currentAnswer + 1
      } else {
        this.results[taskId] = 0
      }
      this.trigger++ // triggers re-evaluation of page()
    },
    tileSelected(e, taskId) {
      e.preventDefault()
      if(this.selectedTaskIds.includes(taskId)) {
        this.selectedTaskIds = this.selectedTaskIds.filter(t => t != taskId)
      } else {
        this.selectedTaskIds.push(taskId)
      }
    },
    clickSelectAll() {
      this.selectedTaskIds = this.allTilesSelected ? [] : this.page.flat().map(t => t.taskId)
    },
    updateSelection(pageTaskIds) {
      this.selectedTaskIds = this.selectedTaskIds.filter(t => pageTaskIds.includes(t))
    },
    magnifyTile(task) {
      this.magnifyDialog = true
      this.magnifyTask = task
    },
    /**
     * Process answers and save
     * @emits answered
     * @emits save
     */
    answerAndSave() {
      let entries = Object.entries(this.results)
      entries.forEach(entry => {
        let answerSheet = { 'answer': this.answers[entry[1]].value }
        let taskId = entry[0]
        this.$emit('answered', { taskId: taskId, answerSheet: answerSheet })
      })
      this.$emit('save')
    },
    onResize() {
      this.aspectRatio = window.innerWidth / window.innerHeight
    },
  },
  mounted() {
    this.onResize()
  },
  created() {
    this.tasks.forEach(task => this.results[task.taskId] = 0)
    this.sortedTasks = this.tasks.sort((a, b) => (a.taskId > b.taskId) ? 1 : -1)
  }
}