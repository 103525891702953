
export default {
  profile: {
    accountDeleted: 'Konto gelöscht! Schade, dass du gehst...',
    accountDeletionFailed: 'Konto konnte nicht gelöscht werden. Bitte logge dich neu ein und versuche es noch einmal.',
    changeUsername: 'Nutzernamen ändern',
    currentUsername: 'Aktueller Nutzername',
    deleteAccount:'Konto löschen',
    deleteYourAccount: 'Möchtest du wirklich dein Konto löschen? Dieser Schritt kann nicht rückgängig gemacht werden!',
    exploreUserGroups: 'Nutzergruppen erkunden',
    failedToJoinGroup: 'Nutzergruppe konnte nicht beigetreten werden',
    failedToLeaveGroup: 'Nutzergruppe konnte nicht verlassen werden',
    failedToSignOut: 'Konnte nicht abmelden',
    groupStats: 'Gruppenstatistiken',
    join: 'Beitreten',
    joinedGroup: 'Nutzergruppe beigetreten',
    joinNewGroup: 'Neuer Gruppe beitreten',
    joinThisGroup: 'Möchtest du dieser Gruppe beitreten?',
    leave: 'Verlassen',
    leaveThisGroup: 'Möchtest du diese Gruppe wirklich verlassen? Beiträge, die du während deiner Mitgliedschaft gemacht hast, werden weiterhin dieser Gruppe zugeschrieben.',
    leftGroup: 'Nutzergruppe verlassen',
    memberships: 'Mitgliedschaften',
    newUsername: 'Neuer Nutzername',
    noGroupsAvailable: 'Keine Gruppen verfügbar',
    profile: 'Profil',
    signOut: 'Möchtest du dich wirklich abmelden?',
    unnamedUser: 'Namenlos',
    userGroups: 'Nutzergruppen',
    usernameChanged: 'Nutzername geändert',
    usernameChangeFailed: 'Benutzername konnte nicht geändert werden',
    userStats: 'Nutzerstatistiken',
  }
}
