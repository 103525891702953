import Auth from "@/services/auth"

export default {
  name: 'RecoverAccount',
  created() {
    this.email = this.$route.params.email
    this.resetToken = this.$route.params.resetToken
  },
  data() {
    return {
      isFormValid: false,
      email: '',
      newPassword: null,
      resetToken: null,
      rules: {
        required: value => !!value || this.$t('auth.required'),
        email: v=> {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || this.$t('auth.invalidEmail')
        },
      },
    }
  },
  watch: {
    // Trigger translation of validation error message on text fields
    '$i18n.locale' () {
      if(this.$refs.email.hasError) this.$refs.email.validate()
    }
  },
  methods: {
    requestReset() {
      if (this.isFormValid) {
        let context = this
       Auth.sendResetPasswordEmail(this.email).then(() => {
        context.showSuccess(context.$t('auth.passwordResetEmailSent'))
        context.$emit('routerPush', 'Signin')
       }).catch(error => {
        let errorMsg = this.$t(`auth.authErrors.${error.code}`)
        if (!errorMsg) {
          errorMsg = this.$t(`auth.authErrors.genericError`)
        }
        context.showError(errorMsg)
       })
      }
    }
  }
}