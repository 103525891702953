import Question from '@/components/question/Question.vue'
import Tiles from '@/components/tiles/Tiles.vue'
import Digitisation from '@/components/digitisation/Digitisation.vue'
import Change from '@/components/change/Change.vue'
import Validate from '@/components/validate/Validate.vue'
import ResultService from '@/services/result-service'
import ProjectData from '@/services/project-data'
import appConfig from '@/config/app-config'
import Auth from '@/services/auth'

export default {
  name: 'Project',
  components: {
    Question,
    Tiles,
    Digitisation,
    Change,
    Validate,
  },
  data() {
    return {
      ready: false,
      tasks: [],
      group: null,
      project: null,
      isFirstContribution: false,
      taskAnswers: [],
      startTime: null,
      mode: 'contribute',
      exitDialog: false,
      finishDialog: false,
      langDialog: false,
    }
  },
  computed: {
    taskComponent() {
      return appConfig.projectTypes[this.project.projectType]?.component
    },
    defaultAnswerLabels() {
      return appConfig.projectTypes[this.project.projectType]?.defaultAnswerLabels
    },
  },
  watch: {
    project() {
      this.startTime = Date.now()
    },
  },
  methods: {
    getQuadKeyFromCoordsAndZoom(x, y, zoom) {
      // Create a quadkey for use with certain tileservers that use them, e.g. Bing
      let quadKey = ''
      for (let i = zoom; i > 0; i -= 1) {
          let digit = 0
          /* eslint-disable no-bitwise */
          const mask = 1 << (i - 1);
          if ((x & mask) !== 0) {
              digit += 1
          }
          if ((y & mask) !== 0) {
              digit += 2
          }
          /* eslint-enable no-bitwise */
          quadKey += digit.toString()
      }
      return quadKey
    },
    getBingURLFromQuadKey(urlTemplate, quadKey, apiKey) {
      let bingUrl = urlTemplate
        .replace(/({quadKey})|({quad_key})/, quadKey)
        .replace(/({apiKey})|({key})/, apiKey)
      return bingUrl
    },
    formatXYZoomKey(urlTemplate, x, y, zoom, apiKey, wmtsLayerName = '') {
      let url = urlTemplate
        .replace('{x}', x.toString())
        .replace('{y}', y.toString())
        .replace('{z}', zoom.toString())
        .replace(/({apiKey})|({key})/, apiKey)
        .replace(/({layer})|({name})/, wmtsLayerName)
      return url
    },
    getTileUrlFromCoordsAndTileserver(x, y, zoom, tileServer) {
      // based on https://github.com/mapswipe/mapswipe/blob/master/src/shared/common/tile_functions.js
      let urlTemplate = tileServer.url
      let apiKey = tileServer.apiKey
      let tileServerName = tileServer.name
      let wmtsLayerName = tileServer.wmtsLayerName
      let url = ''
      if (tileServerName === 'bing') {
          const quadKey = this.getQuadKeyFromCoordsAndZoom(x, y, zoom)
          url = this.getBingURLFromQuadKey(urlTemplate, quadKey, apiKey)
      } else if (tileServerName?.includes('maxar')) {
          const newY = 2 ** zoom - y - 1
          url = this.formatXYZoomKey(tileServer.url, x, newY, zoom, apiKey)
      } else if (urlTemplate.includes('{-y}')) {
          const newY = 2 ** zoom - y - 1
          url = urlTemplate.replace('{-y}', '{y}')
          url = this.formatXYZoomKey(url, x, newY, zoom, apiKey, wmtsLayerName)
      } else {
          url = this.formatXYZoomKey(urlTemplate, x, y, zoom, apiKey, wmtsLayerName)
      }
      return url
    },
    arrayFromMinMax(min, max) {
      min = parseInt(min)
      max = parseInt(max)
      return Array.from({length: max-min+1}, (_, i) => i + min)
    },
    buildTasks(project, group) {
      const xArray = this.arrayFromMinMax(group.xMin, group.xMax)
      const yArray = this.arrayFromMinMax(group.yMin, group.yMax)
      let tasks = []
      for(let x of xArray) {
        for(let y of yArray) {
          let task = {
            groupId: group.groupId,
            projectId: project.projectId,
            taskX: x,
            taskY: y,
            taskId: `${project.zoomLevel}-${x}-${y}`,
            url: this.getTileUrlFromCoordsAndTileserver(x, y, project.zoomLevel, project.tileServer)
          }
          if(project.tileServerB) {
            task.urlB = this.getTileUrlFromCoordsAndTileserver(x, y, project.zoomLevel, project.tileServerB)
          }
          tasks.push(task)
        }
      }
      return tasks
    },
    /**
     * Load app data and set ready as true
     */
    load() {
      let context = this
      let projectId = this.$route.params.projectId
      let uid = this.$store.getters.user.uid
      let completedGroups = this.$store.getters.completedGroupIdsByProject(projectId)

      ProjectData.getProjectData(projectId, uid, completedGroups).then(data => {
        context.project = data.project
        context.project.answerLabels ??= this.defaultAnswerLabels
        context.group = data.group
        context.tasks = data.tasks
        if(!context.tasks.length && this.taskComponent === "tiles") {
          context.tasks = this.buildTasks(data.project, data.group)
        }
        context.ready = true
        context.startTime = Date.now()
        context.isFirstContribution = !data.alreadyContributed

        if(appConfig.filterProjectsByLocale &&
          context.$i18n.availableLocales.includes(context.project.language)) {
            context.$i18n.locale = context.project.language
        }
      })
    },
    /**
     * treat and show save task error
     * @param {*} err 
     */
    showSaveTasksError(err) {
      let errors = this.$t('errors')
      let errorMsg = errors.unknown
      if (err.status && errors.http[err.status]) {
        errorMsg = errors.http[err.status]
      }
      this.showError(errorMsg, { timeout: 0 })
    },
    /**
     * Save current task
     * @param {*} data 
     */
    answered(data) {
      this.taskAnswers.push(data)
    },
    /**
     * Save the results of all tasks and then goes to projects listing
     */
    saveTasks() {
      Auth.tryAutoLogin().then(() => {
        let options = { data: this.buildTaskResultObject(), verb: 'put', query: { auth: this.$store.getters.idToken } }
        let endPoint = ResultService.getEndPoint() + `${this.project.projectId}/${this.group.groupId}/${this.$store.getters.user.uid}.json`
        let context = this
        ResultService.customQuery(options, endPoint).then(() => {
          context.showSuccess(context.$t('project.tasksSaved'))
          context.mode = 'finished'
        }).catch(err => {
          context.showSaveTasksError(err)
        })
      })

      // Commit completed group to store
      this.$store.commit('completedGroups', {
        groupId: this.group.groupId,
        projectId: this.group.projectId
      })

      this.finishDialog = true
    },
    /**
     * Build the task result object
     * 
     * @returns  {*}
     */
    buildTaskResultObject() {
      let taskResults = { startTime: new Date(this.startTime).toISOString(), endTime: new Date().toISOString(), results: {} }
      for (let key in this.taskAnswers) {
        const answerSheet = this.taskAnswers[key].answerSheet
        const answer = Object.keys(answerSheet).length === 1 ? Object.values(answerSheet)[0] : answerSheet
        taskResults.results[this.taskAnswers[key].taskId] = answer
      }
      return taskResults
    },
    continueMapping() {
      this.finishDialog = false
      this.ready = false
      this.mode = 'contribute'
      this.tasks = []
      this.group = null
      this.project = null
      this.taskAnswers = []
      this.startTime = null
      this.load()
    },
    seeAllProjects() {
      this.finishDialog = false
      this.mode = 'noTasks'
      this.$router.push({ name: 'Projects' })
    },
    confirmExit(response) {
      this.exitDialog = false
      this.functionResolve(response)
    },
    createPromise() {
      return new Promise(resolve => {
        this.functionResolve = resolve;
      })
    },
  },
  created() {
    this.load()
    this.eventBus.$on("clickDisabledLocaleSelector", () => {
      this.langDialog = !this.langDialog
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.mode === 'contribute' && to.name !== 'Signin') {
      this.exitDialog = true
      this.createPromise().then(res => next(res))
    } else {
      next()
    }
  }
}