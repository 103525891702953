import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import bg from '@/directives/bg'
import store from '@/store/store'
import VueZoomer from 'vue-zoomer'
import { initializeApp } from 'firebase/app'
import box from '@/components/box/Box.vue'
import title from '@/directives/title'
import vuetify from './plugins/vuetify'
import appConfig from './config/app-config'
import HMenu from '@/components/h-menu/HMenu.vue'
import I18nBuilder from '@/i18n/i18n-builder'
import AppVMenu from '@/components/v-menu/VMenu'
import topBorder from '@/directives/top-border'
import globalMixins from '@/common/global-mixins'
import BasicPage from '@/components/basic-page/BasicPage'
import smartTooltip from '@/directives/smart-tooltip'
import wrapperTag from '@/components/wrapper/wrapper-tag'
import "@mdi/font/css/materialdesignicons.css"

import VueLayers from 'vuelayers'
import 'vuelayers/dist/vuelayers.min.css'

// Create a global event bus, so all the components
// can access it to emit and capture events using this.eventBus
const eventBus = new Vue()
Vue.prototype.eventBus = eventBus
Vue.config.productionTip = false
Vue.use(VueZoomer)
Vue.use(VueLayers)
Vue.use(require('vue-shortkey'))

// add global custom components
Vue.mixin(globalMixins)
Vue.directive('bg', bg)
Vue.component('box', box)
Vue.component('basic-page', BasicPage)
Vue.directive('title', title)
Vue.component('app-h-menu', HMenu)
Vue.component('app-v-menu', AppVMenu)
Vue.directive('top-border', topBorder)
Vue.component('wrapper-tag', wrapperTag)
Vue.directive('smart-tooltip', smartTooltip)

let firebaseApp = initializeApp(appConfig.firebaseConfig)
store.commit('firebase', firebaseApp)

let vueInstance = new Vue({
  store,
  vuetify,
  i18n: I18nBuilder.build(),
  router,
  render: h => h(App)
}).$mount('#app')

export default vueInstance
