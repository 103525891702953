export default {
  name: 'QuestionInstructions',
  props: {
    mediaType: {
      type: String,
      require: true
    },
    questions: {
      type: Array,
      required: true

    },
    attribution: {
      type: String,
      require: false
    },
    textColor: {
      type: Function,
      require: true
    },
    manualUrl: {
      type: String,
      require: false
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    /**
     * Adds a question mark at the end of 
     * a string, if not already there
     * @param {String} question 
     * @returns {String}
     */
    addQuestionMark (question) {
      if(!question.endsWith("?")) {
        question = question.concat("?")
      }
      return question
    },
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.dialog = this.first
  },
}