
export default {
  project: {
    project: 'Project',
    tasksSaved: 'Tasks saved. Now you can start mapping in another project!',
    seeAllProjects: 'See all projects',
    noTaskAvailableTitle: 'No tasks left!',
    noTaskAvailableText: 'You have completed all available tasks in this project. Try another project!',
    continueMapping: 'Continue mapping',
    wellDone: 'Well done!',
    youHaveCompletedAllTheTasks: 'You have completed a sequence of tasks. You can continue mapping in this project or see all the projects available',
    answersWillBeLost: 'Do you really want to leave? All your answers will be lost.',
    notAvailable: 'Not available',
    langChange: 'This @.lower:project.project is only available in @:(global.languageLabel). In order to change the language settings, please leave this @.lower:project.project first.',
  }
}
