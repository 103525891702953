import { marked } from 'marked'

export default {
  name: "Privacy",
  methods: {
    mdToHtml(md) {
      return marked(md)
    }
  }
}
