import Auth from '@/services/auth'

export default {
  name: 'Signin',
  data() {
    return {
      isFormValid: false,
      email: '',
      password: '',
      showPassword: false,
      rules: {
        required: value => !!value || this.$t('auth.required'),
        email: v=> {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || this.$t('auth.invalidEmail')
        },
      },
    }
  },
  watch: {
    // Trigger translation of validation error message on text fields
    '$i18n.locale' () {
      if(this.$refs.email.hasError) this.$refs.email.validate()
      if(this.$refs.password.hasError) this.$refs.password.validate()
    }
  },
  methods: {
    signin() {
      if (this.isFormValid) {
        let context = this
        Auth.signin(this.email, this.password).then((user) => {
          if(user.emailVerified) {
            context.$emit('routerPush', 'Projects')
          } else {
            context.$emit('routerPush', 'RequestPasswordReset')
          }
        }).catch((error) => {
          let errorMsg = this.$t(`auth.authErrors.${error.code}`)
          if (!errorMsg) {
            errorMsg = this.$t(`auth.authErrors.genericError`)
          }
          context.showError(errorMsg)
        })
      }
    }
  }
}