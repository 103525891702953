import TaskProgress from "@/components/task-progress/TaskProgress";
import ValidateInstructions from "@/components/validate-instructions/ValidateInstructions";
import { Attribution, ScaleLine } from "ol/control";
import tinyColor2 from "tinycolor2";

export default {
  name: "Validate",
  components: {
    TaskProgress,
    ValidateInstructions,
  },
  props: {
    tasks: {
      type: Array,
      require: true,
    },
    project: {
      type: Object,
      require: true,
    },
    group: {
      type: Object,
      require: true,
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentTaskGeom: this.tasks[0].geojson,
      transparent: false,
      selectedOptionValue: undefined,
      selectedSubOptionValue: undefined,
      results: {},
      task: {},
      taskIndex: 0,
      isAnswered: {},
      allAnswered: false,
      subOptionsDialog: false,
    };
  },
  computed: {
    isLastTask() {
      return this.taskIndex + 1 === this.tasks.length;
    },
    isFirstTask() {
      return this.taskIndex === 0;
    },

    colors() {
      return this.$vuetify.theme.themes.light;
    },
    question() {
      return this.$t('validate.doesTheShapeOutline') + " " + this.project.lookFor + "?"
    },
    options() {
      let options = this.project.customOptions
      options ??= this.project.answerLabels
      options.forEach(this.completeOptions)
      return options
    },
    selectedOption() {
      const selectedOption = this.options.find(o => o.value == this.selectedOptionValue)
      return selectedOption
    },
    xyzUrl() {
      const apiKey = this.project.tileServer.apiKey
      const wmtsLayerName = this.project.tileServer.wmtsLayerName
      const url = this.project.tileServer.url
        .replace(/({apiKey})|({key})/, apiKey)
        .replace(/({layer})|({name})/, wmtsLayerName)
      return url
    },
  },
  methods: {
    matchIcon(icon) {
      const iconLookup = {
        'add-outline': 'mdi-plus',
        'alert-outline': 'mdi-exclamation',
        'ban-outline':  'mdi-cancel',
        'checkmark-outline': 'mdi-check',
        'close-outline': 'mdi-close',
        'egg-outline': 'mdi-egg-outline',
        'ellipse-outline': 'mdi-ellipse-outline',
        'flag-outline': 'mdi-flag-outline',
        'hand-left-outline': 'mdi-hand-back-left-outline',
        'hand-right-outline': 'mdi-hand-back-right-outline',
        'happy-outline': 'mdi-emoticon-happy-outline',
        'heart-outline': 'mdi-heart-outline',
        'help-outline': 'mdi-help',
        'information-outline': 'mdi-information-outline',
        'prism-outline': 'mdi-pyramid',
        'refresh-outline': 'mdi-refresh',
        'remove-outline':  'mdi-minus',
        'sad-outline': 'mdi-emoticon-sad-outline',
        'search-outline': 'mdi-magnify',
        'shapes-outline': 'mdi-shape',
        'square-outline': 'mdi-square-outline',
        'star-outline': 'mdi-star-outline',
        'thumbs-down-outline': 'mdi-thumb-down-outline',
        'thumbs-up-outline': 'mdi-thumb-up-outline',
        'triangle-outline': 'mdi-triangle-outline',
        'warning-outline': 'mdi-alert-outline',
      }

      const mdiIcon = iconLookup[icon]
      return mdiIcon
    },
    completeOptions(option, index) {
      option.title ??= option.label
      option.iconColor ??= option.color || '#d1dad1'
      option.value ??= index
      option.subOptionValues = [option.value]
      option.subOptions?.forEach(subOption => option.subOptionValues.push(subOption.value))
      option.shortkey = this.optionShortkey(index)
      option.mdiIcon = this.matchIcon(option.icon) || option.icon
      return option
    },
    parentOptionValue(subOptionValue) {
      const parentOptionValue = this.options.find(o => o.subOptionValues?.includes(subOptionValue))?.value
      return(parentOptionValue)
    },
    hex2rgb(hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return [r, g, b, alpha];
    },
    contrastingTextColor(bg) {
      const textColor = tinyColor2(bg).isLight() ? "black" : "white";
      return textColor;
    },
    adjustOutlineColor(col, amount = 20) {
      const isLight = tinyColor2(col).isLight()
      const outlineColor = isLight ? tinyColor2(col).darken(amount) : tinyColor2(col).lighten(amount)
      return outlineColor
    },
    optionShortkey(optionIndex) {
      const shortkey = optionIndex < 9 ? optionIndex + 1 : "";
      return shortkey;
    },
    /**
     * Update result with value
     * @param {*} value
     * @param {*} slot
     */
    optionSelected(option) {
      const value = option.value
      this.selectedOptionValue = value;

      if(!option.subOptions) {
        this.results[this.task.taskId] = value;
        this.isAnswered[this.task.taskId] = true;
        this.allAnswered =
          this.allAnswered ||
          (this.isLastTask && this.isAnswered[this.task.taskId]);
      } else {
        this.subOptionsDialog = true
      }
    },
    closeSubOptionsDialog() {
      this.results[this.task.taskId] = this.selectedSubOptionValue;
      this.isAnswered[this.task.taskId] = true;
      this.allAnswered =
        this.allAnswered ||
        (this.isLastTask && this.isAnswered[this.task.taskId]);
      this.subOptionsDialog = false
    },
    /**
     * Go to task by task index,
     * create result object if not existent and
     * reset question index
     * @param {*} index
     */
    goToTask(index) {
      this.task = this.tasks[index];
      this.results[this.task.taskId] ??= null;
      this.isAnswered[this.task.taskId] ??= false;
      this.selectedSubOptionValue = this.results[this.task.taskId];
      this.selectedOptionValue = this.parentOptionValue(this.selectedSubOptionValue)
      this.currentTaskGeom = this.tasks[this.taskIndex].geojson;
    },
    /**
     * Go to next task
     */
    nextTask() {
      this.taskIndex++;
      this.goToTask(this.taskIndex);
      this.fitView();
    },
    /**
     * Go back to previous task
     */

    previousTask() {
      this.taskIndex--;
      this.goToTask(this.taskIndex);
      this.fitView();
    },
    /**
     * Back button event handler
     */
    back() {
      if (!this.isFirstTask) this.previousTask();
    },

    /**
     * Forward button event handler
     */
    forward() {
      if (this.isLastTask || !this.isAnswered[this.task.taskId]) {
        return;
      } else {
        this.nextTask();
      }
    },
    /**
     * Emit answerSheets for all tasks as events and save the task answers
     * @emits answered
     * @emits save
     */
    answerAndSave() {
      let entries = Object.entries(this.results);
      entries.forEach((entry) => {
        let answerSheet = entry[1];
        let taskId = entry[0];
        this.$emit("answered", { taskId: taskId, answerSheet: answerSheet });
      });
      this.$emit("save");
    },
    makeFeatureFromGeom(geometry) {
      const feature = { geometry: geometry, type: "Feature" };
      return feature;
    },
    fitView(duration = 600, delay = 100) {
      setTimeout(() => {
        this.$refs.mapView.$view.fit(
          this.$refs.taskSource.$source.getExtent(),
          {
            size: this.$refs.map.$map.getSize(),
            padding: [20, 20, 20, 20],
            maxZoom: this.project.tileServer.maxZoom | 19,
            duration: duration,
          }
        );
      }, delay);
    },
    addControls(map) {
      map.getControls().forEach((control) => {
        if (control instanceof Attribution) {
          map.removeControl(control);
        }
      });
      map.addControl(new Attribution({ collapsible: false, collapsed: false }));
      map.addControl(new ScaleLine());
    },
  },
  mounted() {
    this.goToTask(this.taskIndex);
    this.fitView(1200, 600);
  },
};
