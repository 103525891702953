import { signInWithEmailAndPassword, signOut, getAuth, browserLocalPersistence, setPersistence, sendPasswordResetEmail, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth"
import store from '@/store/store'
import UserService from "@/services/user-service";

class Auth { 
   /**
   * Get the profile of the current authenticated user
   * @returns {Object|null}
   */
  static getUserProfile () {
    return new Promise((resolve) => {
      const auth = getAuth()
      setPersistence(auth, browserLocalPersistence).then(() => {
        const user = auth.currentUser
        if (auth.currentUser) {
          auth.currentUser.getIdToken().then(idToken => {
            store.commit('idToken', idToken)
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            resolve(user)
          })
        } else {
          resolve(null)
        }
      }) 
    })
  }

  /**
   * Logout the user that is currently authenticated by 
   * sending a request to the API that will invalidate the cookies/session
   * @returns {Promise}
   */
  static signout() {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      setPersistence(auth, browserLocalPersistence).then(() => {
        signOut(auth).then(() => {
          store.commit('isAuthenticated', false)
          store.commit('isVerified', false)
          resolve()
        }).catch((error) => {
          
          reject(error)
        })
      })   
    })   
  }

  static sendResetPasswordEmail(email) {
    return new Promise((resolve, reject) => {
      const auth = getAuth()
      setPersistence(auth, browserLocalPersistence).then(() => {
        let url = `${location.protocol}//${location.host}/#/auth`
        sendPasswordResetEmail(auth, email, {url }).then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      })   
    })   
  }

  static signin(email, password) {
    return new Promise((resolve, reject) => {
      let auth = getAuth()
      setPersistence(auth, browserLocalPersistence).then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            let user = userCredential.user
            store.commit('isAuthenticated', true)
            store.commit('isVerified', user.emailVerified)
            resolve(user)
          })
          .catch((error) => {
            reject(error)
          })      
        })
    })
  }

  static signup(email, password, displayName) {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(getAuth(), email, password)
        .then((userCredential) => {
          const user = userCredential.user
          const url = `${location.protocol}//${location.host}/#/auth`
          const userData = {
            created: new Date().toISOString(),
            groupContributionCount: 0,
            projectContributionCount: 0,
            taskContributionCount: 0,
            username: displayName
          }
          const options = { data: userData, verb: 'patch', query: { auth: user.accessToken } }
          const endPoint = UserService.getEndPoint() + `/${user.uid}.json`
          return Promise.all([
            sendEmailVerification(user, { url }),
            updateProfile(user, { displayName: displayName }),
            UserService.customQuery(options, endPoint)
          ])
        })
        .then(() => {
          resolve()
        })
        .catch(err => {    
          reject(err)
        })
      });

  }

  static tryAutoLogin() {
    return new Promise((resolve, reject) => {
      Auth.getUserProfile().then((user) => {
        resolve(user)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}

export default Auth