export default {
  name: 'DigitisationInstructions',
  props: {
    instructionMsg: {
      type: String,
      required: true
    },
    lookFor: {
      type: String,
      required: true
    },
    manualUrl: {
      type: String,
      require: false
    },
    showNavigation: {
      type: Boolean,
      default: true
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dialog: false,
    eyeOff: false,
  }),
  computed: {
    title () {
      return "title"
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
  },
  created () {
    this.dialog = this.first
  },
}