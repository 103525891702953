const themes = [
  { 
    name: "default",
    light: {
      primary: '#C62828', // color used for header
      secondary: '#8e0000',
      accent: '#ff5f52',
      error: '#C62828', // color used to show error toaster
      warning: '#FFD54F', // color used to show warning toaster
      info: '#2196f3', // color used to show info toaster
      success: '#4caf50', // color used to show success toaster
      neutral: '#2E2E2E' // color used for footer
    },
  },
  { 
    name: "UndercoverEisAgenten",
    light: {
      primary: '#006f87', // from UEA-Logo_rgb-dark-L.png
      secondary: '#003543', // from UEA-Logo_rgb-dark-L.png
      tertiary: '#94d1e8', // from UEA-Logo_rgb-dark-L.png
      accent: '#fedf44', // from UEA-Logo_rgb-dark-L.png
      error: '#C62828', 
      warning: '#8E0000', 
      info: '#2196f3', // from UEA-Logo_rgb-dark-L.png
      success: '#4caf50', 
      neutral: '#272727'
    },
  },
  {
  name: "MapSwipe",
    light: {
      primary: '#060E2F', // MapSwipe blue
      secondary: '#0D1949', // darker version of MapSwipe blue
      tertiary: '#EEF2FB', // very light blue
      accent: '#589AE3',
      error: '#C62828',
      warning: '#8E0000',
      info: '#2196f3',
      success: '#4caf50',
      neutral: '#272727'
    },
  }
]
export default themes
