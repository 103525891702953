import Privacy from '@/pages/privacy/Privacy.vue'

const routes = [
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    beforeEnter: (to, from, next) => {
      next()
    }
  }
]

export default routes
