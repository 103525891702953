import ExampleImages from '@/components/example-images/ExampleImages'
import QuestionInstructions from '@/components/question-instructions/QuestionInstructions'
import TaskProgress from '@/components/task-progress/TaskProgress'
import tinyColor2 from 'tinycolor2'
import appConfig from '@/config/app-config'


export default {
  name: 'Question',
  components: {
    TaskProgress,
    QuestionInstructions,
    ExampleImages
  },
  props: {
    tasks: {
      type: Array,
      require: true
    },
    project: {
      type: Object,
      require: true
    },
    group: {
      type: Object,
      require: true
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      selected: undefined,
      results: {},
      currentQuestionIndex: 0,
      task: {},
      taskIndex: 0, 
      zoomed: false,
      isAnswered: {}, 
      allAnswered: false,
      imgIsLoaded: false,
    }
  },
  computed: {
    attribution () {
      const attribution = this.project.mediaCredits || ""
      return attribution
    },
    questions () {
      const questions = [{ 
        'title': this.project.lookFor, 
        'slot': 'answer', 
        'answers': this.project.answerLabels 
      }]

      // if answer.value is undefined, use answer index (integer)
      const setAnswerValue = (answer, index) => {
        answer.value ??= index
        return answer
      }
      questions[0].answers.map(setAnswerValue)

      // add confidence question if includeConfidenceQuestion set to true in project
      if(this.project.includeConfidenceQuestion) {
        questions.push(appConfig.confidenceQuestion)
      }
      return questions
    },
    isLastQuestion () {
      return (this.currentQuestionIndex + 1) === this.questions.length
    },
    isLastTask () {
      return (this.taskIndex + 1) === this.tasks.length
    },
    isFirstTask () {
      return this.taskIndex === 0
    },
    mediaFileExtension () {
      return new URL(this.task.media).pathname.split('.').pop().toLowerCase()
    },
    isImageTask () {
      return ['jpeg', 'jpg', 'gif', 'png', 'svg', 'bmp'].includes(this.mediaFileExtension)
    },
    isVideoTask () {
      return ['mp4', 'webm', 'ogg'].includes(this.mediaFileExtension) 
    },
    appTheme () {
      return this.$vuetify.theme.themes.light
    },
    isMapillary() {
      return this.project.mediaCredits?.includes("Mapillary")
    },
  },
  methods: {
    /**
     * Get a contrasting text color for a given background color
     * @param {String} bg 
     * @returns {String}
     */
    contrastingTextColor (bg) {
      const textColor = tinyColor2(bg).isLight() ? 'black' : 'white'
      return textColor
    },
    /**
     * Execute a zoom action (in or out)
     * @param {String} action 
     */
    zoom(action) {
      if (action === 'in') {
        this.$refs.zoomer[0].zoomIn()
      } else {
        this.$refs.zoomer[0].zoomOut()
      }
    },
    resetZoom() {
      this.$refs.zoomer[0].reset()
    },
    answerShortkey(answerIndex) {
      const shortkey = answerIndex < 9 ? answerIndex + 1 : ''
      return shortkey
    },
    /**
     * Update result for question slot with value
     * @param {*} value 
     * @param {*} slot 
     */
    answerSelected (value, slot) {
      this.selected = value
      this.results[this.task.taskId][slot] = value 
      this.isAnswered[this.task.taskId] = this.isLastQuestion
      this.allAnswered = this.allAnswered || this.isLastTask && this.isAnswered[this.task.taskId]
    },
    /**
     * Go to task by task index, 
     * create result object if not existent and
     * reset question index
     * @param {*} index 
     */
    goToTask (index) {
      this.task = this.tasks[index]
      this.results[this.task.taskId] ??= {}
      this.isAnswered[this.task.taskId] ??= false
      this.selected = this.results[this.task.taskId][this.questions[this.currentQuestionIndex].slot]
      this.currentQuestionIndex = 0
      if(this.$refs.zoomer) this.$refs.zoomer[0].reset()
      this.imgIsLoaded = false
    },
    /**
     * Go to next task
     */
    nextTask () {
      this.taskIndex++
      this.goToTask(this.taskIndex)
    },
    /**
     * Go back to previous task
     */
    previousTask () {
      this.taskIndex--
      this.goToTask(this.taskIndex)
    },
    /**
     * Check if an answer button value equals the
     * currently selected answer and return string
     * to toggle between checked/unchecked radiobox icon 
     * @param {*} answerValue 
     * @returns 
     */
    toggleRadiobox (value) {
      const taskId = this.task.taskId
      const questionSlot = this.questions[this.currentQuestionIndex].slot
      const activeAnswer = this.results[taskId][questionSlot]
      return value === activeAnswer ? "marked" : "blank"
    },
    /** 
     * Back button event handler
     */
    back () {
      if(!this.isFirstTask) this.previousTask()
    },
    /**
     * Forward button event handler
     */
    forward () {
      if((this.isLastTask && this.isLastQuestion) || this.selected === undefined) {
        return
      }
      else if (this.isLastQuestion) {
        this.nextTask()
      } else {
        this.currentQuestionIndex++
      }
    },
    /**
     * Emit answerSheets for all tasks as events and save the task answers
     * @emits answered
     * @emits save
     */
    answerAndSave() {
      let entries = Object.entries(this.results)
      entries.forEach(entry => {
        let answerSheet = entry[1]
        let taskId = entry[0]
        this.$emit('answered', { taskId: taskId, answerSheet: answerSheet })
      })
      this.$emit('save')
    },
    /**
     * Get the help component based on the 
     * currently selected task template
     * and a given answer index
     * @param {Number} answerIndex 
     * @returns {Object}
     */
    helpComponent (answerIndex) {
      let question = this.questions[this.currentQuestionIndex]
      let answer = question.answers[answerIndex]
      if (answer.help) {
        let componentName = answer.help.componentName
        let component
        switch (componentName) {
          case 'example-images':
            component = ExampleImages
            break    
        }
        return component
      }
    },
    /**
     * Get the help component resources based on the 
     * currently selected task template
     * and a given answer index
     * @param {Number} answerIndex 
     * @returns {Object}
     */
    helpComponentResources (answerIndex) {
      let question = this.questions[this.currentQuestionIndex]
      let answer = question.answers[answerIndex]
      if (answer.help) {
        let resources = answer.help.resources
        resources.projectType = this.project.projectType
        return resources
      }
    },
    onImgLoad () {
      this.imgIsLoaded = true
    },
  },
  created() {    
    this.goToTask(this.taskIndex)
  }
}