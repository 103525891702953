import ProjectService from '@/services/project-service'
import GroupService from '@/services/group-service'
import TaskService from '@/services/task-service'
import UserService from '@/services/user-service'
import lodash from 'lodash'
import pako from 'pako'
import base64 from 'base-64'

class ProjectData { 
  /**
   * Load project data from the service
   * @param {String} userId
   * @param {*} completedGroups
   * @returns {Promise}
   */
  static getProjectData(projectId, userId, completedGroups = []) {
    return new Promise((resolve, reject) => {
      let data = {}
      let idProjectPath = `${projectId}.json`

      ProjectService.get(idProjectPath).then(project => {
      data.project = project
      ProjectData.getProjectTasksForRandomGroup(projectId, userId, completedGroups).then(result => {
        data.group = result.group
        data.alreadyContributed = result.alreadyContributed
        data.tasks = typeof result.tasks == 'string' ? this.decompressTasks(result.tasks) : result.tasks
        resolve(data)
      }).catch(err => {
        reject(err)
      })
      }).catch(err => {
        reject(err)
      })
    })      
  }

  /**
   * Get the tasks for a random group of a project
   * @param {*} projectId 
   * @param {*} userId 
   * @param {*} completedGroups
   * @returns {Promise}
   */
  static getProjectTasksForRandomGroup (projectId, userId, completedGroups = []) {
    return new Promise((resolve, reject) => {
      // query groups in project
      let groupPromise = GroupService.query({ orderBy: '"requiredCount"'} , `${projectId}.json`)
      // query groups in user contributions to the project
      let userContributionsPromise = UserService.query({}, `/${userId}/contributions/${projectId}.json`)
      Promise.all([groupPromise, userContributionsPromise]).then((results) => {

        /*
        get ids of recently completed groups from store and
        add to groups in user contributions queried from firebase
        */
        var answeredGroups = results[1].data
        var taskContributionCount = answeredGroups.taskContributionCount
        completedGroups.forEach(groupId => answeredGroups[groupId] = true)

        let data = {}
        data.group = ProjectData.selectRandomGroupNotAnswered(results[0].data, answeredGroups)
        data.alreadyContributed = completedGroups.length > 0 || taskContributionCount > 0

        if (!data.group) {
          data.tasks = []
          resolve(data)
        } else {
          TaskService.query({}, `/${projectId}/${data.group.groupId}.json`).then(response => {
            data.tasks = response.data
            resolve(data)              
          }).catch(err => {
            reject(err)
          })
        }
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * Select a random group that is not yet completed by the user
   * and has a requiredCount greater than zero
   * @param {*} groups 
   * @param {*} answeredGroups 
   * @returns {*} group
   */
  static selectRandomGroupNotAnswered (groups, answeredGroups) {
    let notAnsweredGroups = lodash.filter(groups, function(g) {
      return !answeredGroups[g.groupId] && g.requiredCount > 0
    })
    let keysArr = Object.keys(notAnsweredGroups)
    if (keysArr.length === 0) {
      return null
    }
    let groupKey = keysArr[~~(Math.random() * keysArr.length)]
    let selectedGroup = notAnsweredGroups[groupKey]
    return selectedGroup
  }

  /**
   * Decompress zipped tasks
   * @param {*} tasks
   * @returns {*} tasks
   */
  static decompressTasks (tasks) {
    const strTasks = base64.decode(tasks)
    const charTasks = strTasks.split('').map(function(x) {return x.charCodeAt(0)} )
    const binaryTasks = new Uint8Array(charTasks)
    const expandedTasks = pako.inflate(binaryTasks, { to: 'string', })
    return JSON.parse(expandedTasks)
  }
}

export default ProjectData