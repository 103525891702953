import TaskProgress from '@/components/task-progress/TaskProgress'
import DigitisationInstructions from '@/components/digitisation-instructions/DigitisationInstructions'
import { Attribution, ScaleLine } from 'ol/control'
import { createBox } from 'ol/interaction/Draw'

export default {
  name: 'Digitisation',
  components: {
    TaskProgress,
    DigitisationInstructions,
  },
  props: {
    tasks: {
      type: Array,
      require: true
    },
    project: {
      type: Object,
      require: true
    },
    group: {
      type: Object,
      require: true
    },
    first: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      taskIndex: null,
      currentTaskGeom: this.tasks[0].geojson,
      drawnFeatures: [],
      endReached: false,
      fab: false,
      interaction: 'draw',
      drawing: false,
      transparent: false,
      withinTaskGeom: false,
      mapCursor: 'default',
      selectedFeatures: [],
      topmostFeatureAtPixelId: undefined,
    }
  },
  computed: {
    colors: function () {
      return this.$vuetify.theme.themes.light
    },
    snap () {
      return typeof this.drawType === 'undefined' || this.drawType.match(/^(Polygon|LineString)$/)
    },
    instructionMsg () {
      const msg = this.$t('digitisation.' + `${this.project.drawType?.toLowerCase() || "polygon"}` + 'Instruction')
      return msg
    },
    drawType () {
      return this.project.drawType === "Box" ? "Circle" : this.project.drawType
    },
    geometryFunction () {
      return this.project.drawType === "Box" ? createBox() : undefined
    },
    bckDisabled() {
      return this.taskIndex === 0
    },
    fwdDisabled() {
      return this.taskIndex === this.tasks.length - 1
    },
  },
  methods: {
    hex2rgb (hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      return [ r, g, b, alpha ]
    },
    drawCondition() {
      return this.withinTaskGeom || this.drawing
    },
    drawStart(e) {
      this.drawing = true
      e.feature.setProperties({ 'taskIndex': this.taskIndex, 'taskId': this.tasks[this.taskIndex].taskId})
    },
    drawEnd() {
      this.drawing = false   
      this.$emit('geometryChange', this.drawnFeatures)
    },
    modifyEnd() {
      this.$emit('geometryChange', this.drawnFeatures)
    },
    deleteFeature(e) {
      if (this.interaction === 'delete') {
        this.$refs.vectorLayer.getFeatures(e.pixel)
          .then((clickedFeature) => this.drawnFeatures = this.drawnFeatures.filter(f => f.id != clickedFeature[0]?.id_))
        this.selectedFeatures = []
        this.$emit('geometryChange', this.drawnFeatures)
      }
    },
    filterSelection (feature, layer) {
      return layer.get('id') == 'vectorLayer' && feature.id_ == this.topmostFeatureAtPixelId
    },
    pointerMove(e) {
      this.$refs.taskLayer.getFeatures(e.pixel).then(f => this.withinTaskGeom = f?.length > 0)
      this.$refs.vectorLayer.getFeatures(e.pixel).then(f => this.topmostFeatureAtPixelId = f[0]?.id_)
      this.mapCursor = this.interaction === 'delete' && this.topmostFeatureAtPixelId
        ? 'pointer'
        : this.drawCondition() ? 'default' : 'grab'
    },
    pointerDrag() {
      this.mapCursor = 'grabbing'
    },
    back() {
      if(!this.bckDisabled) {
        this.taskIndex--
        this.currentTaskGeom = this.tasks[this.taskIndex].geojson
        this.fitView()
      }
    },
    forward() {
      if(!this.fwdDisabled) {
        this.taskIndex++
        if (this.taskIndex === this.tasks.length -1) this.endReached = true
        this.currentTaskGeom = this.tasks[this.taskIndex].geojson
        this.fitView()
      }
    },
    /**
     * Process answers and save
     * @emits answered
     * @emits save
     */
    answerAndSave() {
      const groupBy = (x, f) => x.reduce((a, b, i) => ((a[f(b, i, x)] ||=[] ).push(b.geometry), a), { });
      const results = groupBy(this.drawnFeatures, f => f.properties.taskId)
      const entries = Object.entries(results)

      entries.forEach(entry => {
        let answerSheet = { 'geom': entry[1] }
        let taskId = entry[0]
        this.$emit('answered', { taskId: taskId, answerSheet: answerSheet })
      })
      this.$emit('save')
    },
    makeFeatureFromGeom(geometry) {
      const feature = {"geometry": geometry, "type": "Feature"}
      return feature
    },
    fitView(duration = 600, delay = 100) {     
      setTimeout(() => {
        this.$refs.mapView.$view.fit(this.$refs.taskSource.$source.getExtent(), {
          size: this.$refs.map.$map.getSize(),
          padding: [20, 20, 20, 20],
          maxZoom: this.project.tileServer.maxZoom | 19,
          duration: duration,
        })
      }, delay)
    },
    addControls(map) {
      map.getControls().forEach((control) => {
        if(control instanceof Attribution) { 
          map.removeControl(control)
        }
      })
      map.addControl(new Attribution({collapsible: false, collapsed: false}))
      map.addControl(new ScaleLine())
    },
    xyzUrl(tileServer) {
      const apiKey = tileServer.apiKey
      const wmtsLayerName = tileServer.wmtsLayerName
      const url = tileServer.url
        .replace(/({apiKey})|({key})/, apiKey)
        .replace(/({layer})|({name})/, wmtsLayerName)
      return url
    },
  },
  mounted() {
    // this is a bit awkward, but for some reason necessary to trigger rendering the task features
    this.taskIndex = 0
    this.fitView(1200, 600)
  },
}