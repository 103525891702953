
export default {
  projects: {
    projects: 'Projekte',
    noProjectsAvailable: "Leider sind keine @:projects.projects verfügbar",
    completed: 'Abgeschlossen',
    byYou: 'durch dich',
    filter: 'Filter',
    by: 'durch',
    contributor: 'Beitragende*n | Beitragende',
    filterLabels: {
      language: 'Sprache',
      projectRegion: 'Region',
      projectTopic: 'Thema',
      requestingOrganisation: 'Organisation',
      status: 'Status',
    }
  }
}
