import { render, staticRenderFns } from "./Digitisation.vue?vue&type=template&id=5267904d&"
import script from "./digitisation.js?vue&type=script&lang=js&"
export * from "./digitisation.js?vue&type=script&lang=js&"
import style0 from "./digitisation.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VChip,VCol,VFlex,VIcon,VLayout,VRow,VSpacer,VSpeedDial,VTooltip})
