
const state = {
  leftSideBarOpen: false,
  leftSideBarPinned: false,
  topBarOpen: true,
  footerHeight: 130,
  headerHeight: 93,
  sidebarFullWidth: 400,
  sidebarShrunkWidth: 290,
  sidebarHeaderHeight: 175
}

const getters = {
  leftSideBarOpen: state => {
    return state.leftSideBarOpen
  },
  leftSideBarPinned: state => {
    return state.leftSideBarPinned
  },
  topBarOpen: state => {
    return state.topBarOpen
  },
  footerFullHeight: state => {
    return state.footerFullHeight
  },
  footerHeight: state => {
    return state.footerHeight
  },
  headerHeight: state => {
    return state.headerHeight
  },
  sidebarHeaderHeight: state => {
    return state.sidebarHeaderHeight
  },
  sidebarFullWidth: state => {
    return state.sidebarFullWidth
  },
  sidebarShrunkWidth: state => {
    return state.sidebarShrunkWidth
  },
  isSidebarVisible: state => {
    return state.leftSideBarOpen || state.leftSideBarPinned
  }
}

const mutations = {
  setLeftSideBarIsOpen: (state, isOpen) => {
    state.leftSideBarOpen = isOpen
  },
  setLeftSideBarIsPinned: (state, isPined) => {
    state.leftSideBarPinned = isPined
    // When the sidebar is pinned, we also
    // set is as open
    if (isPined) {
      state.leftSideBarOpen = true
    }
  },
  setTopBarIsOpen: (state, isOpen) => {
    state.topBarOpen = isOpen
  }
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
