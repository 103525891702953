import Projects from '@/pages/projects/Projects.vue'
import store from '@/store/store'

const routes = [
  {
    path: '/projects/:projectId?',
    name: 'Projects',
    component: Projects,
    beforeEnter: (to, from, next) => {    
      if (store.getters.isVerified) {
        next()
      } else if (!store.getters.isAuthenticated) {
        next({name: 'Signin', params: {fromProjects: true} })
      } else {
        next({name: 'RequestPasswordReset'})
      }
    },
    
  }
]

export default routes
