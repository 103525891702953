
const state = {
  firebase: null,
  user: null,
  isAuthenticated: false,
  isVerified: false,
  idToken: null,
  completedGroups: [],
  selectedFilterItems: null
}

const getters = { 
  firebase: state => {
    return state.firebase
  },
  user: state => {
    return state.user
  },
  isAuthenticated: state => {
    return state.isAuthenticated
  },
  isVerified: state => {
    return state.isVerified
  },
  idToken: state => {
    return state.idToken
  },
  completedGroupIdsByProject: (state) =>  (projectId) => {
    return state.completedGroups.filter(g => g.projectId === projectId).map(g => g.groupId)
  },
  selectedFilterItems: state => {
    return state.selectedFilterItems
  },
}

const mutations = {
  firebase: (state, firebase) => {
    state.firebase = firebase
  },
  user: (state, user) => {
    state.user = user
  },
  isAuthenticated: (state, isAuthenticated) => {
    state.isAuthenticated = isAuthenticated
  },
  isVerified: (state, isVerified) => {
    state.isVerified = isVerified
  },
  idToken: (state, idToken) => {
    state.idToken = idToken
  },
  completedGroups: (state, group) => {
    state.completedGroups.push(group)
  },
  selectedFilterItems: (state, selectedItems) => {
    state.selectedFilterItems = selectedItems
  },
}

const actions = {
}

export default {
  state,
  getters,
  mutations,
  actions
}
