import Home from '@/pages/home/Home.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      next()
    }
  }
]

export default routes
