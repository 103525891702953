
export default {
  tilesInstructions: {
    howToContribute: 'How to contribute?',
    classifyTitle: 'Click or tap to classify',
    classifyInstruction: 'Click or tap each image tile, until the appropriate answer appears',
    selectionTitle: 'Select multiple tiles',
    selectTile: '... or right click/hold touch to add a tile to the selection. Repeat to remove the tile from the selection',
    selectAll: 'Select all tiles in view. Repeat to clear selection',
    selectionInstruction: 'Any click to classify applies to all selected tiles',
    useButtonsToNavigate: 'Use buttons to navigate',
    moveColumn: 'Move left/right by column',
    movePage: 'Move left/right by page',
    toggleOpacityTitle: '@.capitalize:global.toggle the @:tiles.overlay @:global.opacity',
    toggleOpacityInstruction: 'For a clearer view of the underlying imagery, @:global.toggle the @:global.opacity of the @:tiles.overlay',
    haveCloserLook: 'Have a closer look',
    magnifyInstruction: 'Click this icon in the upper right corner of a tile to magnify the image',
    saveYourAnswers: 'Save your answers',
    seenAll: 'your answers as soon as you have seen and classified all images.',
    imageCredits: 'Image credits',
    },
}
