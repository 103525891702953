import { render, staticRenderFns } from "./TaskProgress.vue?vue&type=template&id=2e8dc860&"
import script from "./task-progress.js?vue&type=script&lang=js&"
export * from "./task-progress.js?vue&type=script&lang=js&"
import style0 from "./task-progress.css?vue&type=style&index=0&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VChip,VProgressLinear})
