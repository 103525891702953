
export default {
  digitisation: {
    changeTool: 'Change tool',
    draw: 'Draw',
    modify: 'Modify',
    delete: 'Delete',
    moveLeft: 'Back to previous area',
    moveRight: 'On to next area',
    boxInstruction: 'Draw bounding boxes around all relevant features within the marked area',
    circleInstruction: 'Draw all relevant circles within the marked area. Click to set the center of the circle and drag the cursor to increase the radius. Click again to finish editing the circle',
    linestringInstruction: 'Draw all relevant lines within the marked area. Click to set the nodes one after another. Double click to set the end of the line',
    pointInstruction: 'Click to mark all relevant points within the marked area.',
    polygonInstruction: 'Draw all relevant polygons within the marked area. Click to set the nodes one after another. Double click to close the polygon, or click once more on the first node',
  }
}
