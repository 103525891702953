export default {
  name: 'ExampleImages',
  data: () => ({
    isModalOpen: false
  }),
  props: {
    resources: {
      type: Object,
      require: true
    }
  },
  computed: {
    title () {
      return this.resources.title
    },
    images () {
      return this.resources.images
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
  },
}