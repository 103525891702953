
export default {
  auth: {
    email: 'E-Mail',
    invalidEmail: '@:auth.email ungültig',
    displayName: 'Anzeigename',
    password: 'Passwort',
    confirmPassword: '@:auth.password bestätigen',
    passwordMatch: '@:auth.password stimmt nicht überein',
    newPassword: 'neues @:auth.password',
    minChar: 'Minimum {number} Zeichen',
    forgotPassword: '@:auth.password vergessen?',
    account: 'Konto',
    consent: 'Ich erkläre mich damit einverstanden, dass die von mir angegebenen personenbezogenen Daten (z.B. Vorname, Nachname, E-Mail, Kommentar) in der Crowdmapping Anwendung vom HeiGIT verwendet werden.{newline}{newline}Das HeiGIT wird die hier bereitgestellten Informationen ausschließlich in Übereinstimmung mit den {action} verwenden. Diese Einwilligung ist freiwillig. Der Widerruf dieser Einwilligung ist jederzeit per E-Mail an eprivacy@heigit.org für die Zukunft möglich.',
    privacyNotice: "Datenschutzhinweisen",
    noAccountYet: 'Du hast noch kein @:auth.account?',
    signin: 'Anmelden',
    signup: 'Registrieren',
    recover: 'Wiederherstellen',
    required: 'Dies ist ein Pflichtfeld.',
    emailNotVerified: '@.upper:auth.email noch nicht verifiziert.',
    signinFailed: '@:auth.signin gescheitert',
    followInstructionOnYourEmail: 'Bitte folge den Anweisungen, die wir an deine @:(auth.email) geschickt haben. Es kann bis zu 20 Minuten dauern, bis die @:auth.email ankommt.',
    signupFailed: '@:auth.signup gescheitert',
    requestResetFailed: 'Das Senden der @:auth.email zum Zurücksetzen des @:(auth.password)s ist gescheitert',
    passwordResetEmailSent: '@:auth.email zum Zurücksetzen des @:(auth.password)s versandt',
    pleaseSignIn: 'Bitte @.lower:auth.signin oder @.lower:auth.signup',
    signInToAccessProjects: 'Um Zugang zu den Projekten zu erhalten, musst du dich @.lower:auth.signin oder @.lower:auth.signup',
    authErrors: {
      genericError: 'Die Aktion konnte nicht abgeschlossen werden',
      not_verified: '@:auth.account nicht verifiziert',
      'auth/user-not-found': 'Nutzer nicht gefunden',
      'auth/invalid-email': 'Ungültige @:auth.email',
      'auth/wrong-password': '@:auth.password oder @:auth.email falsch',
      'auth/email-already-in-use': 'Ein @:auth.account mit dieser @:auth.email besteht bereits.',
    }    
  }
}