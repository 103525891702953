
export default {
  tilesInstructions: {
    howToContribute: 'Wie kannst du beitragen?',
    classifyTitle: 'Bildkacheln klassifizieren',
    classifyInstruction: 'Klicke oder tippe jede Bildkachel, bis die passende Antwort erscheint',
    selectionTitle: 'Mehrere Bildkacheln auswählen',
    selectTile: '... oder Rechtsklick/Halten, um die Bildkachel der Auswahl hinzuzufügen. Wiederholen, um sie von der Auswahl zu entfernen',
    selectAll: 'Alle Bildkacheln der aktuellen Ansicht auswählen. Wiederholen, um die Auswahl zu leeren',
    selectionInstruction: 'Jeder Klick zur Klassifizierung wird auf alle Bildkacheln in der Auswahl angewandt',
    useButtonsToNavigate: 'Navigationstasten',
    moveColumn: 'Spaltenweise links/rechts bewegen',
    movePage: 'Seitenweise links/rechts bewegen',
    toggleOpacityTitle: '@:global.opacity @.lower:global.toggle',
    toggleOpacityInstruction: 'Schalte die Durchsichigkeit der oberen Schicht für eine bessere Sicht auf die Bildkacheln um',
    haveCloserLook: 'Schau genauer hin',
    magnifyInstruction: 'Klicke das Symbol in der oberen rechten Ecke der Bildkachel, um das Bild zu vergrößern',
    saveYourAnswers: 'Speichere deine Antworten',
    seenAll: 'der Antworten, sobald du alle Bildkacheln gesehen und klassifiziert hast',
    imageCredits: 'Bildnachweis',
   },
}
