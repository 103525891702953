export default {
  name: 'TaskProgress',
  data() {
    return {
      progress: null,
      done: null
    }
  },
  props: {
    progressIndex: {
      type: Number,
      require: true
    },
    total: {
      type: Number,
      require: true
    }
  },
  watch: {
    progressIndex: {
      handler: function () {
       this.setPercentage()
      },
      deep: true
    },
    total: {
      handler: function () {
       this.setPercentage()
      },
      deep: true
    }
  },
  methods: {
    setPercentage () {
      if (this.progressIndex >=0 & this.total > 0) {
        this.progress = Math.round((this.progressIndex * 100) / this.total).toString()
      }
    }
  },
  mounted () {
    this.setPercentage()
  }
}