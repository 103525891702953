
export default {
  tiles: {
    compare: 'Vergleiche die',
    layer: '-Schicht',
    tiles: 'Bildkacheln',
    withTiles: 'mit den @:tiles.tiles',
    lookFor: 'Suche nach',
    inTiles: 'in den @:tiles.tiles',
    overlay: 'obere Schicht',
    moveLeft: 'Eine Spalte nach links bewegen | {columns} Spalten nach links bewegen',
    moveRight: 'Eine Spalte nach rechts bewegen | {columns} Spalten nach rechts bewegen',
  }
}
