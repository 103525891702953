import Help from '@/pages/help/Help.vue'


const routes = [
  {
    path: '/help',
    name: 'Help',
    component: Help,
    beforeEnter: (to, from, next) => {
      next()
    }
  }
]

export default routes
