
export default {
  questionInstructions: {
    howToContribute: 'Wie kannst du beitragen?',
    provideYourAnswer: 'Antworten',
    lookAtThe: 'Sieh dir das',
    image: 'Bild | Bilder',
    video: 'Video | Videos',
    answerButton: 'an und gib deine Antwort ab, indem du den passenden Antwort-Knopf klickst oder antippst',
    afterAnswering: 'Nachdem du dein Antwort abgegeben hast, erscheint eine zweite Frage',
    indicateConfidence: 'Gib an, wie sicher du dir bist, dass deine Antwort auf die erste Frage richtig ist',
    useButtonsToNavigate: 'Navigationstasten',
    moveBack: 'Gehe zurück, um deine vorherigen Antworten zu verändern',
    moveForward: 'Gehe einen Schritt weiter, nachdem du eine Antwort zur aktuellen Fragen abgegeben hast',
    saveYourAnswers: 'Speichere deine Antworten',
    seenAll: 'sobald du alle',
    answeredAll: 'gesehen und alle Fragen beantwortet hast',
    imageCredits: 'Bildnachweis',
    zoomTitle: 'Sieh genau hin',
    zoomInstruction: 'Bild @.lower:global.zoomIn and @.lower:global.zoomOut',
    resetInstruction: '@:global.resetZoom und Ansicht zentrieren',
    }
  }
