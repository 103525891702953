import Project from '@/pages/project/Project.vue'
import store from '@/store/store'

const routes = [
  {
    path: '/projects/:projectId',
    name: 'Project',
    component: Project,
    beforeEnter: (to, from, next) => {    
      if (store.getters.isVerified) {
        next()
      } else if (!store.getters.isAuthenticated) {
        next({name: 'Signin'})
      } else {
        next({name: 'RequestPasswordReset'})
      }
    },
    
  }
]

export default routes
