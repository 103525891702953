import {ModelService} from 'vue-rest-client'
import httpClientOptions from '@/common/http-client-options'
import utils from '@/support/utils'

const options = {
  httpClientOptions: httpClientOptions,
  transformRequest: (request) => {
    utils.addIdTokenToRequest(request)  
  },
  raw: true
}
const taskRunService = new ModelService('/v2/users', 'users', options)

export default taskRunService
