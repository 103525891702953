import Vue from 'vue';
import themes from '@/config/themes'
import appConfig from '@/config/app-config'
import Vuetify from 'vuetify'

const selectedTheme = themes.filter(theme => theme.name === appConfig.theme)[0]

Vue.use(Vuetify)
export default new Vuetify({
       
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: selectedTheme.light,
            dark: selectedTheme.dark
        }
    }, 
    icons: {
        iconfont: 'mdi',
    }
})
