
export default {
  project: {
    project: 'Projekt',
    tasksSaved: 'Antworten gespeichert. Du kannst jetzt in einem anderen Projekt kartieren!',
    seeAllProjects: 'Alle Projekte anschauen',
    noTaskAvailableTitle: 'Keine Aufgaben übrig!',
    noTaskAvailableText: 'Du hast alle verfügbaren Aufgaben in diesem Projekt abgeschlossen. Versuche ein anderes Projekt!',
    continueMapping: 'Weiter kartieren',
    wellDone: 'Gut gemacht!',
    youHaveCompletedAllTheTasks: 'Du hast eine Reihe von Aufgaben abgeschlossen. Du kannst in diesem Projekt weitermachen oder dir alle verfügbaren Projekte ansehen.',
    answersWillBeLost: 'Möchtest du die Seite wirklich verlassen? All deine Antworten gehen verloren.',
    notAvailable: 'Nicht verfügbar',
    langChange: 'Dieses @:project.project ist nur auf @:global.languageLabel verfügbar. Um die Spracheinstellungen zu verändern, verlasse bitte zuerst dieses @:(project.project).',
  }
}
