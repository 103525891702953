import * as menuMixin from '@/common/menu-mixin'

export default {
  props: {
    item:
    {
      type: Object,
      required: true
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    showMenuItemFn: {
      type: Function,
      required: false
    },
    navigateFn: {
      type: Function,
      required: false
    }
  },
  watch: {
    $route: function () {
      this.$forceUpdate()
    },
    '$store.getters.isAuthenticated': function () {
      this.$forceUpdate()
    },
  },
  methods: {
    ...menuMixin,
    renderMenu (item) {
      let render = item.items && Array.isArray(item.items) && this.showMenuItem(item)
      return render
    },
    renderMenuItem (item) {
      let render = !item.divider && !item.header && !item.notInHeader && this.showMenuItem(item)
      return render
    },
    nav (to) {
      if (this.navigateFn) {
        this.navigateFn(to)
      } else {
        this.navigate(to)
      }
    }
  }
}
