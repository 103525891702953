
export default {
  digitisation: {
    changeTool: 'Werkzeug wechseln',
    draw: 'Zeichnen',
    modify: 'Ändern',
    delete: 'Löschen',
    moveLeft: 'Zurück zum vorherigen Gebiet',
    moveRight: 'Weiter zum nächsten Gebiet',
    boxInstruction: 'Zeichne die begrenzenden Rechtecke um alle relevanten Objekte innerhalb des markierten Gebietes',
    circleInstruction: 'Zeichne innerhalb des markierten Gebietes alle relevanten Kreise ein. Setzte durch Klicken den Kreismittelpunkt und ziehe den Mauszeiger, um den Radius zu vergrößern. Klicke erneut, um die Bearbeitung des Kreises zu beenden',
    linestringInstruction: 'Zeichne innerhalb des markierten Gebietes alle relevanten Linien ein, indem du nacheinander durch Klicken Knotenpunkte setzt. Ein Doppelklick setzt das Ende einer Linie',
    pointInstruction: 'Markiere durch Klicken alle relevanten Punkte innerhalb des markierten Gebietes',
    polygonInstruction: 'Zeichne innerhalb des markierten Gebietes alle relevanten Polygone, indem du nacheinander durch Klicken Knotenpunkte setzt. Schließe ein Polygon durch Doppelklick oder erneuten Klick auf den ersten Knoten',
  }
}
