import appConfig from "../../config/app-config"

export default {
  name: 'LocaleSelector',
  props: {
  },
  data: () => ({
  }),
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    availableLocales () {
      return this.$i18n.availableLocales
    },
  },
  methods: {
    isDisabled () {
      return appConfig.filterProjectsByLocale && this.$route.name === 'Project'
    },
    clickDisabled () {
      if(this.isDisabled()) {
        this.eventBus.$emit("clickDisabledLocaleSelector")
      }
    }
  },
  created () {
  }
}