
export default {
  changeInstructions: {
    howToContribute: 'Wie kannst du beitragen?',
    provideYourAnswer: 'Antworten',
    taskInstruction: 'Vergleiche {lookFor} in den "@:change.before"- and "@:change.after"-Bildern und gib deine Antwort ab, indem du den passenden Antwort-Knopf klickst oder antippst.',
    useButtonsToNavigate: 'Navigationstasten',
    moveBack: 'Gehe zurück zum vorherigen Bildpaar',
    moveForward: 'Gehe weiter nach Beurteilung des aktuellen Bildpaars',
    saveYourAnswers: 'Speichere deine Antworten',
    seenAll: 'sobald du alle Bildpaare beurteilt hast',
    imageCredits: 'Bildnachweis',
    }
  }
