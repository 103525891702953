import NotFound from '@/pages/not-found/NotFound'

const routes = [
  {
    path: '*', 
    name: '404',
    component: NotFound
  }
]

export default routes
