
export default {
  auth: {
    email: 'E-mail',
    invalidEmail: '@:auth.email invalid',
    displayName:  'Username',
    password: 'Password',
    confirmPassword: 'Confirm @.lower:auth.password',
    passwordMatch: '@:auth.password does not match',
    minChar: 'Minimum {number} characters',
    newPassword: 'new @.lower:auth.password',
    forgotPassword: 'Forgot your @.lower:auth.password?',
    account: 'Account',
    consent: 'I agree that the personal data I have provided (e.g. first name, last name, email, comment) may be used by HeiGIT in the crowdmapping application.{newline}{newline}HeiGIT will use the information provided here solely in accordance with the {action}. This consent is voluntary. The revocation of this consent is possible at any time by e-mail to eprivacy@heigit.org for the future.',
    privacyNotice: "Privacy Notice",
    noAccountYet: 'Don\'t have an @.lower:auth.account yet?',
    signin: 'Sign in',
    signup: 'Sign up',
    recover: 'Recover',
    required: 'This field is required.',
    emailNotVerified: '@:auth.email not verified yet.',
    signinFailed: '@:auth.signin failed',
    followInstructionOnYourEmail: 'Please follow the instructions sent to your @:(auth.email). It may take up to 20 minutes for the @:auth.email to arrive.',
    signupFailed: '@:auth.signup failed',
    requestResetFailed: 'Sending @:auth.password reset @:auth.email failed',
    passwordResetEmailSent: '@.upper:auth.password reset @:auth.email sent',
    pleaseSignIn: 'Please @.lower:auth.signin or @.lower:auth.signup',
    signInToAccessProjects: 'In order to access projects, you need to @.lower:auth.signin or @.lower:auth.signup',
    authErrors: {
      genericError: 'The action could not be completed',
      not_verified: '@:auth.account not verified',
      'auth/user-not-found': 'User not found',
      'auth/invalid-email': 'Invalid @:auth.email',
      'auth/wrong-password': 'Wrong @:auth.password or @:auth.email',
      'auth/email-already-in-use': '@:auth.email already in use.',
    }    
  }
}
