
export default {
  validateInstructions: {
    howToContribute: 'How to contribute?',
    provideYourAnswer: 'Provide your answer',
    taskInstruction: '{question} Compare it with the imagery and and provide your answer to the question by clicking or tapping the appropriate answer button.',
    useButtonsToNavigate: 'Use buttons to navigate',
    moveBack: 'Move back to the previous shape',
    moveForward: 'Move forward to the next shape',
    toggleOpacityTitle: '@.capitalize:global.toggle the @:tiles.overlay @:global.opacity',
    toggleOpacityInstruction: 'For a clearer view of the underlying imagery, @:global.toggle the @:global.opacity of the @:tiles.overlay',
    resetTitle: 'Reset the view',
    resetInstruction: 'Zoom to the current shape',
    saveYourContributions: 'Save your answers',
    seenAll: 'as soon as you have evaluated all shapes',
    }
  }
