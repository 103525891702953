
export default {
  projects: {
    projects: 'Projects',
    noProjectsAvailable: "Sorry, there are no @.lower:projects.projects available",
    completed: 'Completed',
    byYou: 'by you',
    filter: 'Filter',
    by: 'by',
    contributor: 'contributor | contributors',
    filterLabels: {
      language: 'Language',
      projectRegion: 'Region',
      projectTopic: 'Topic',
      requestingOrganisation: 'Organization',
      status: 'Status',
    }
  }
}
