import ProjectService from '@/services/project-service'
import ProjectData from '@/services/project-data'
import appConfig from '@/config/app-config'
import VueMarkdown from 'vue-markdown-render'


export default {
  name: 'Projects',
  components: {
    VueMarkdown,
  },
  data () {
    return {
      currentProject: null,
      ready: false,
      task: null,
      projects: [],
      selectedItems: appConfig.projectFilters.map(f => f.defaultItems),
      showFilterCard: false,
      moreInfoDialog: false,
    }
  },
  computed: {
    fallbackImage () {
      return require('@/assets/img/map-pin-600x400.jpg')
    },
    projectFilters () {
      let filters = []
      if(appConfig.projectFilters && Array.isArray(appConfig.projectFilters)) {
        filters = appConfig.projectFilters?.map((filter, i) => {
          if(filter.multiple && !Array.isArray(filter.defaultItems)) {
            filter.defaultItems = new Array(filter.defaultItems)
            this.selectedItems[i] = filter.defaultItems
          }
          if(!filter.multiple && Array.isArray(filter.defaultItems) && filter.defaultItems.length > 0) {
            filter.defaultItems = filter.defaultItems[0]
            this.selectedItems[i] = filter.defaultItems
          }
          return filter
        })
      }
      return filters
    },
    filteredProjects () {
      const projects = this.filterProjects()
      return projects
    },
    filterShow () {
      const filterShow = this.projectFilters.map(f => f.show)
      return filterShow
    },
    filterIsAvailable () {
      const filterIsAvailable = this.filterShow.some(e => e)
      return filterIsAvailable
    },
    shownFilterIsSet () {
      const filterSelections = (e, i) => this.filterShow[i] && e.length > 0
      const shownFiltersSet = this.selectedItems.map(filterSelections).some(e => e)
      return shownFiltersSet
    },
    noProjects () {
      return !Object.keys(this.filteredProjects).length
    },
    availableProjectTypes () {
      let availableProjectTypes = Object.keys(appConfig.projectTypes)
      return availableProjectTypes
    },
  },
  methods: {
    selectProject(project) {
      this.$router.push({name: 'Project', params: {projectId: project.projectId}})
    },
    selectRandomItem (Obj) {
      let keysArr = Object.keys(Obj)
      let drawnKey = keysArr[~~(Math.random() * keysArr.length)]
      let drawItem = Obj[drawnKey]
      return drawItem
    },
    projectIsAvailable(project) {
      let available = !appConfig.disableProjectAlreadyCompleted || project.hasAvailableTasks === true
      return available
    },
    setProjectAccessibility () {
      // Get project data for each project and set the property `hasAvailableTasks` if it has available tasks
      for (let key in this.projects) {
        let context = this
        let projectId = context.projects[key].projectId
        let uid = this.$store.getters.user.uid
        let completedGroups = this.$store.getters.completedGroupIdsByProject(projectId)
        ProjectData.getProjectData(projectId, uid, completedGroups).then(data => {
          if (data.project.progress < 100 && data.group) {
            let projectId = data.project.projectId
            context.projects[projectId].hasAvailableTasks = true
            context.$forceUpdate()
          }
        }).catch(err => {
          console.log(err)
        })
      }    
    },
    filterProjects (filterIndex) {
      let projects = Object.entries(this.projects)
      
      for(let i in this.projectFilters) {
        if(i == filterIndex) continue
        projects = projects.filter(project => {
          const filter = this.projectFilters[i]
          const value = project[1][filter.attribute]
          const items = this.selectedItems[i]
          
          if(items.length === 0) {
            return true
          } else {
            return filter.multiple ? items.includes(value) : value === items
          }
        })
      }

      if(appConfig.filterProjectsByLocale) {
        projects = projects.filter(project => project[1].language === this.$i18n.locale)
      }

      // filter for projects of available types
      projects = projects.filter(p => this.availableProjectTypes.includes(p[1].projectType?.toString()))

      projects = Object.fromEntries(projects)
      return projects
    },
    translateFilterLabel (label, attribute) {
      const translationName = 'projects.filterLabels.' + attribute
      return label || (this.$te(translationName) ? this.$t(translationName) : attribute)
    },
    showMoreInfo (project) {
      this.currentProject = project
      this.moreInfoDialog = true
    },
    /**
     * Load app dat and state, including user logged in, project and task
     */
    load () {
      let context = this
      let filters = { orderBy: '"status"', equalTo: '"active"'}
      ProjectService.query(filters, '.json').then(response => {
        context.projects = response.data
        context.setProjectAccessibility()
      })
    }
  },
  created() {
    this.load()
    const storedFilterItems = this.$store.getters.selectedFilterItems
    if(storedFilterItems) this.selectedItems = storedFilterItems
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('selectedFilterItems', this.selectedItems)
    next()
  }

}