
export default {
  validateInstructions: { 
    howToContribute: 'Wie kannst du beitragen?',
    provideYourAnswer: 'Antworten',
    taskInstruction: '{question} Vergleich die Form mit den Bilddaten und gib deine Antwort ab, indem du den passenden Antwort-Knopf klickst oder antippst.',
    useButtonsToNavigate: 'Navigationstasten',
    moveBack: 'Gehe zurück zur vorherigen Form',
    moveForward: 'Gehe weiter zur nächsten Form',
    toggleOpacityTitle: '@:global.opacity @.lower:global.toggle',
    toggleOpacityInstruction: 'Schalte die Durchsichigkeit der oberen Schicht für eine bessere Sicht auf die Bildkacheln um',
    resetTitle: 'Ansicht zurücksetzen',
    resetInstruction: 'Zoom auf die aktuelle From',
    saveYourContributions: 'Speichere deine Antworten',
    seenAll: '... sobald du alle Formen beurteilt hast',
    }
  }
