import RecoverAccount from '@/components/recover-account/RecoverAccount.vue'
import Signin from '@/components/signin/Signin.vue'
import Signup from '@/components/signup/Signup.vue'

export default {
  name: 'AuthPage',
  components: {
    Signin,
    Signup,
    RecoverAccount
  }, 
  data: () => ({
    activeTab: '',
    tabs: [
      {name: 'auth.signin', route: '/auth/signin', component: "Signin"},
      {name: 'auth.signup', route: '/auth/signup', component: "Signup"}, 
      {name: 'auth.recover', route: '/auth/request-password-reset', component: "RecoverAccount"}
    ],
    projectsDialog: false,
  }),
  watch: {
    // adjust tab slider width to new tab names on language change
    '$i18n.locale' () {
      this.$refs.tabs.callSlider()
    }
  },
  methods: {
    routerPush (name) {
      this.$router.push({name: name})
    },
  },
  mounted() {
    if(this.$route.params.fromProjects) this.projectsDialog = true
  },
}
