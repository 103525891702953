
export default {
  digitisationInstructions: {
    howToContribute: 'How to contribute?',
    modeTitle: 'Switch editing mode',
    drawFeature: 'Draw new features',
    modifyFeature: 'Modify features',
    deleteFeature: 'Delete features',
    useButtonsToNavigate: 'Use buttons to navigate',
    moveBack: 'Move back to the previous area',
    moveForward: 'Move on to the next area',
    toggleOpacityTitle: '@.capitalize:global.toggle the @:tiles.overlay @:global.opacity',
    toggleOpacityInstruction: 'For a clearer view of the underlying imagery, @:global.toggle the @:global.opacity of the @:tiles.overlay',
    resetTitle: 'Reset the view',
    resetInstruction: 'Zoom to the current area',
    saveYourContributions: 'Save your contributions',
    seenAll: 'your contributions as soon as you have mapped all areas',
    imageCredits: 'Image credits',
    },
}
