export default {
  name: 'Auth',
  data () {
    return {
    }
  },
  methods: { },
  computed: {    
    initials () {
      if (!this.user) {
        return ''
      }
      let name = this.user.displayName || this.user.email 
      let initials = name.charAt(0)
      if (name.indexOf(' ') > -1) {
        initials += name.split(' ')[1].charAt(0)
      }
      return initials.toUpperCase()
    },
    show () {
      return this.$store.getters.user !== null
    },
    user () {
      return this.$store.getters.user
    }
  }
}