import resolver from '@/support/routes-resolver'
import appConstants from '@/resources/constants'
import appConfig from '@/config/app-config'
import UserState from '@/components/user-state/UserState'
import LocaleSelector from '@/components/locale-selector/LocaleSelector'


export default {
  components: {
    UserState,
    LocaleSelector,
  },
  data () {
    return {
      drawer: true,
      clipped: false,
      menuItems: appConstants.menuItems,
    }
  },
  methods: {
    toggleSidebar () {
      this.highlightMenu = false
      this.$store.commit('setLeftSideBarIsOpen', !this.$store.getters.leftSideBarOpen)
    },
    getConfigVal (key) {
      let configVal = appConfig[key]
      return configVal
    },
  },
  computed: {
    homeUrl () {
      const url = resolver.homeUrl()
      return url
    },
    showToolbar () {
      // we can't wait for the $store.getters.embed to be set 
      let contains = location.hash.includes('/embed/')
      return !contains
    },
  }
}