export default {
  changeInstructions: {
    howToContribute: 'How to contribute?',
    provideYourAnswer: 'Provide your answer',
    taskInstruction: 'Compare {lookFor} in the "@:change.before" and "@:change.after" images and and provide your answer by clicking or tapping the appropriate answer button.',
    useButtonsToNavigate: 'Use buttons to navigate',
    moveBack: 'Move back to the previous image pair',
    moveForward: 'Move forward after having provided an answer to the current image pair',
    saveYourAnswers: 'Save your answers',
    seenAll: 'as soon as you have evaluated all the image pairs',
    imageCredits: 'Image credits',
    }
  }
