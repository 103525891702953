export default {
  props: {
    pageName: String,
    flexWidths: {
      type: Array,
      default: () => {
        return ['xs12', 'sm10', 'md8', 'lg6', 'xl4']
      }
    }
  }
}
